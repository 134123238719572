import { useState } from "react";
import { IoMailOpenOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { IoVideocam } from "react-icons/io5";
import { LuPenSquare } from "react-icons/lu";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";
import { ReactTyped } from "react-typed";

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <header className="relative z-50">
        <div className="w-full px-12 py-2 mixedGradients hidden lg:flex flex-row items-center justify-between fixed z-50">
          <div className="flex flex-row items-center gap-4 justify-start z-50">
            <a
              href="tel:+48515516387"
              className="flex flex-row items-center justify-end gap-2 text-white infoBannerLink cursor-pointer"
            >
              <FiPhoneCall className="text-xl text-white" />
              <span>+48 515 516 387</span>
            </a>
            <a
              href="mailto:hello@ifil.pl"
              className="flex flex-row items-center justify-end gap-2 text-white infoBannerLink cursor-pointer"
            >
              <IoMailOpenOutline className="text-xl" />
              <span>hello@ifil.pl</span>
            </a>
            <a
              href="https://outlook.office365.com/owa/calendar/FilipKaniaiFilGroup@ifil.pl/bookings/"
              className="flex flex-row items-center justify-end gap-2 text-white infoBannerLink cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <IoVideocam className="text-xl text-white" />
              <span>Spotkajmy się</span>
            </a>
          </div>
          <div className="flex flex-row items-center gap-4 justify-end">
            <a
              href="/wycena"
              className="flex flex-row items-center justify-end gap-2 text-white infoBannerLink cursor-pointer"
            >
              <LuPenSquare className="text-xl text-white" />
              <span>Bezpłatna wycena</span>
            </a>
            <a
              href="/program-partnerski"
              className="flex flex-row items-center justify-end gap-2 text-white infoBannerLink cursor-pointer"
            >
              <FaHandshake className="text-xl text-white" />
              <span>Program partnerski</span>
            </a>
          </div>
        </div>
        <div className="Header absolute px-12 pb-6 pt-16 hidden lg:flex flex-row items-center justify-between text-white z-40">
          <a href="/" className="w-1/6">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/logo-white.png"
              alt=""
            />
          </a>
          <div className="flex flex-row items-center justify-end w-full gap-6">
            <nav className="flex flex-row items-center justify-end gap-6">
              <a className="headerLink" href="/sklepy-internetowe">
                Sklepy on-line
              </a>
              <a className="headerLink" href="/strony-internetowe">
                Strony internetowe
              </a>
              <a className="headerLink" href="/aplikacje">
                Aplikacje
              </a>
              <a className="headerLink" href="/portfolio">
                Realizacje
              </a>
              <a className="headerLink" href="/o-nas">
                O nas
              </a>
            </nav>
            <div className="flex flex-row items-center justify-end gap-4">
              <a href="/pomoc" className="shrink-0">
                <button className="secondButton flex flex-row items-center justify-center gap-2 shrink-0">
                  <MdOutlineSupportAgent className="colorMain text-xl shrink-0" />
                  <span className="shrink-0 block xl:hidden">Pomoc</span>
                  <span className="shrink-0 hidden xl:block">
                    Poradniki i wsparcie
                  </span>
                </button>
              </a>
              <a href="/bezplatna-konsultacja">
                <button className="firstButton flex flex-row items-center justify-center gap-2 w-52 animate-pulse">
                  <IoMailOpenOutline className="text-white text-xl" />
                  <span>
                    Zbudujmy{" "}
                    <ReactTyped
                      strings={["stronę", "sklep", "aplikację", "CRM"]}
                      typeSpeed={100}
                      loop
                      backSpeed={20}
                      cursorChar="|"
                      showCursor={true}
                    />
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </header>
      <div className="flex flex-col items-center justify-center lg:hidden fixed top-12 right-12 z-[9999999]">
        <button
          onClick={handleMobileMenuToggle}
          className={`bg-white ${
            isMobileMenuOpen ? "absolute" : "hidden"
          } w-12 h-12 flex flex-col gap-1 p-2 items-center justify-center firstShadowLightWhite`}
        >
          <span className="mixedGradients w-6" style={{ height: "2px" }}></span>
          <span className="mixedGradients w-6" style={{ height: "2px" }}></span>
          <span className="mixedGradients w-6" style={{ height: "2px" }}></span>
        </button>
        <button
          onClick={handleMobileMenuToggle}
          className={`mixedGradients ${
            isMobileMenuOpen ? "hidden" : "absolute"
          } w-12 h-12 flex flex-col gap-1 p-2 items-center justify-center firstShadowLight`}
        >
          <span className="bg-white w-6" style={{ height: "2px" }}></span>
          <span className="bg-white w-6" style={{ height: "2px" }}></span>
          <span className="bg-white w-6" style={{ height: "2px" }}></span>
        </button>
      </div>
      <div
        id="contentMobileMenu"
        className={`w-full h-screen top-0 left-0 fixed mixedGradients z-[999999] ${
          isMobileMenuOpen ? "block" : "hidden"
        }`}
      >
        <div className="p-12 flex flex-col items-center justify-center w-full h-full gap-4">
          <a className="headerLink" href="/">
            Home
          </a>
          <a className="headerLink" href="/sklepy-internetowe">
            Sklepy on-line
          </a>
          <a className="headerLink" href="/strony-internetowe">
            Strony internetowe
          </a>
          <a className="headerLink" href="/aplikacje">
            Aplikacje
          </a>
          <a className="headerLink" href="/portfolio">
            Realizacje
          </a>
          <a className="headerLink" href="/program-partnerski">
            Program partnerski
          </a>
          <a href="/pomoc" className="border border-white rounded-[25px]">
            <button className="firstButton flex flex-row items-center justify-center gap-1">
              <MdOutlineSupportAgent className="text-xl text-white" />
              <span>Poradniki i wsparcie</span>
            </button>
          </a>
          <a href="/wycena">
            <button className="secondButton flex flex-row items-center justify-center gap-1 text-center">
              <LuPenSquare className="text-xl mainColor" />
              <span>Bezpłatna wycena</span>
            </button>
          </a>
          <div className="flex flex-col items-center justify-center mt-12 gap-2">
            <a
              className="text-white fontRegular text-xl"
              href="tel:+48515516387"
            >
              +48 515 516 387
            </a>
            <a
              className="text-white fontRegular text-xl"
              href="mailto:hello@ifil.pl"
            >
              hello@ifil.pl
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
