function HowWeWork() {
  return (
    <section
      id="jak-dzialamy"
      className="blackColorBg px-6 lg:px-48 flex flex-col lg:flex-row items-center justify-center pb-12 lg:pb-0 gap-12 relative letsofferGradient"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 w-full text-white">
        <div className="relative flex flex-col pb-24 items-center justify-start">
          <div className="lg:sticky lg:top-36  flex flex-col items-center justify-center relative">
            <img
              className="w-full"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/porownajsolary-ui.webp"
              alt="Mockup"
            />
            <div className="absolute bottom-0 left-0 px-2 w-40 h-40 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
              <p className="text-center">
                Nasza autorska aplikacja z branży OZE
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-3xl lg:text-6xl whiteHeading text-left mb-12">
            Jak <span className="colorHeading fontRegular">pracujemy</span>?
          </h2>
          <div className="w-full flex flex-col gap-12 lg:gap-36">
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">01</p>
              <p className="whiteHeading text-2xl lg:text-6xl">
                Poznanie potrzeb
              </p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Badamy dogłębnie potrzeby klienta, aby dostosować nasze
                rozwiązania do ich wymagań biznesowych.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">02</p>
              <p className="whiteHeading text-2xl lg:text-6xl">Research</p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Przeprowadzamy gruntowne badania rynku i analizy konkurencji,
                aby zapewnić skuteczne strategie dla projektu.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">03</p>
              <p className="whiteHeading text-2xl lg:text-6xl">Oferta</p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Oferujemy 3-4 warianty cenowe, umożliwiając klientom pełne
                porównanie i wybór najbardziej odpowiedniego rozwiązania.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">04</p>
              <p className="whiteHeading text-2xl lg:text-6xl">Umowa</p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Korzystamy z platformy Autenti, zapewniając elektroniczne
                podpisy i bezpieczne umowy dla naszych klientów.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">05</p>
              <p className="whiteHeading text-2xl lg:text-6xl">UI / UX</p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Projektujemy intuicyjne interfejsy użytkownika, zapewniając nie
                tylko estetykę, ale także użyteczność i komfort użytkowania.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">06</p>
              <p className="whiteHeading text-2xl lg:text-6xl">Development</p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Nasz zespół programistów przekształca pomysły w funkcjonalne
                rozwiązania, dbając o każdy detal i wydajność.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">07</p>
              <p className="whiteHeading text-2xl lg:text-6xl">
                Sesje poprawkowe
              </p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Zapewniamy sesje poprawkowe, aby dopasować nasze produkty do
                oczekiwań klienta, zgodnie z ustaleniami.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">08</p>
              <p className="whiteHeading text-2xl lg:text-6xl">
                Wdrożenie i instruktaż
              </p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Przeprowadzamy kompleksowe wdrożenie, zapewniając płynną
                migrację i gotowość do działania od pierwszego dnia.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-6">
              <p className="whiteHeading text-2xl lg:text-6xl">09</p>
              <p className="whiteHeading text-2xl lg:text-6xl">Wsparcie</p>
              <p className="text-white w-full mt-2 opacity-60 text-xl">
                Świadczymy bezpłatne wsparcie, bezterminowe, poprzez infolinię,
                mail oraz panel klienta, gwarantując pełną satysfakcję i sukces
                projektu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default HowWeWork;
