import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Offer from "./pages/Offer";
import Apps from "./pages/Apps";
import Shops from "./pages/Shops";
import Websites from "./pages/Websites";
import CompanyWebsites from "./pages/CompanyWebsites";
import Crm from "./pages/Crm";
import Ai from "./pages/Ai";
import Mvp from "./pages/Mvp";
import Refresh from "./pages/Refresh";
import Interfaces from "./pages/Interfaces";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import DocumentsTerms from "./pages/DocumentsTerms";
import DocumentsPolicy from "./pages/DocumentsPolicy";
import DocumentsInformation from "./pages/DocumentsInformation";
import AccountLogin from "./pages/AccountLogin";
import DocumentsPartner from "./pages/DocumentsPartner";
import Partner from "./pages/Partner";
import AccountRegister from "./pages/AccountRegister";
import AccountPanel from "./pages/AccountPanel";
import AccountNewTicket from "./pages/AccountNewTicket";
import AccountBooking from "./pages/AccountBooking";
import PricingQuote from "./pages/PricingQuote";
import LetsOfferWebsite from "./pages/LetsOfferWebsite";
import Employee from "./pages/Employee";
import Brief from "./pages/Brief";

import { Navigate } from "react-router-dom";
import LetsOfferShop from "./pages/LetsOfferShop";
import LetsOfferApp from "./pages/LetsOfferApp";
import ThankForm from "./pages/Thanks";
import { HelmetProvider } from "react-helmet-async";
import Hotjar from "@hotjar/browser";
import PageNotFound from "./pages/PageNotFound";
import SeoHome from "./pages/SeoHome";
import Reservation from "./pages/Reservation";
import Automation from "./pages/Automation";
import Support from "./pages/Support";
import SupportTicket from "./pages/SupportTicket";
import SupportBlogPost from "./pages/SupportBlogPost";
import SupportBlog from "./pages/SupportBlog";
import SupportBlogCategory from "./pages/SupportBlogCategory";
import SupportBlogSubcategory from "./pages/SupportBlogSubcategory";
import Problems from "./pages/Problems";
import LandingPayNow from "./pages/LandingPayNow";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = localStorage.getItem("authToken");

  return isAuthenticated ? <>{children}</> : <Navigate to="/panel/login" />;
};

const siteId = 3908518;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const seoPages = [
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-bedzin",
    title: "Tworzenie stron sklepów aplikacji internetowych Będzin",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-bialystok",
    title: "Tworzenie stron sklepów aplikacji internetowych Białystok",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-bielany",
    title: "Tworzenie stron sklepów aplikacji internetowych Bielany",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-bielsko-biala",
    title: "Tworzenie stron sklepów aplikacji internetowych Bielsko Biała",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-bydgoszcz",
    title: "Tworzenie stron sklepów aplikacji internetowych Bydgoszcz",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-bytom",
    title: "Tworzenie stron sklepów aplikacji internetowych Bytom",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-chorzow",
    title: "Tworzenie stron sklepów aplikacji internetowych Chorzów",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-czestochowa",
    title: "Tworzenie stron sklepów aplikacji internetowych Częstochowa",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-dabrowa-gornicza",
    title: "Tworzenie stron sklepów aplikacji internetowych Dąbrowa Górnicza",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-elblag",
    title: "Tworzenie stron sklepów aplikacji internetowych Elbląg",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-gdansk",
    title: "Tworzenie stron sklepów aplikacji internetowych Gdańsk",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-gdynia",
    title: "Tworzenie stron sklepów aplikacji internetowych Gdynia",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-gliwice",
    title: "Tworzenie stron sklepów aplikacji internetowych Gliwice",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-gorzow-wielkopolski",
    title:
      "Tworzenie stron sklepów aplikacji internetowych Gorzów Wielkopolski",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-jelenia-gora",
    title: "Tworzenie stron sklepów aplikacji internetowych Jelenia Góra",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-kalisz",
    title: "Tworzenie stron sklepów aplikacji internetowych Kalisz",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-katowice",
    title: "Tworzenie stron sklepów aplikacji internetowych Katowice",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-kielce",
    title: "Tworzenie stron sklepów aplikacji internetowych Kielce",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-kolobrzeg",
    title: "Tworzenie stron sklepów aplikacji internetowych Kołobrzeg",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-konin",
    title: "Tworzenie stron sklepów aplikacji internetowych Konin",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-konstancin",
    title: "Tworzenie stron sklepów aplikacji internetowych Konstancin",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-koszalin",
    title: "Tworzenie stron sklepów aplikacji internetowych Koszalin",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-krakow",
    title: "Tworzenie stron sklepów aplikacji internetowych Kraków",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-lancut",
    title: "Tworzenie stron sklepów aplikacji internetowych Łańcut",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-legnica",
    title: "Tworzenie stron sklepów aplikacji internetowych Legnica",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-lodz",
    title: "Tworzenie stron sklepów aplikacji internetowych Łódź",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-lowicz",
    title: "Tworzenie stron sklepów aplikacji internetowych Łowicz",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-lubin",
    title: "Tworzenie stron sklepów aplikacji internetowych Lubin",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-lublin",
    title: "Tworzenie stron sklepów aplikacji internetowych Lublin",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-mikolow",
    title: "Tworzenie stron sklepów aplikacji internetowych Mikołów",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-minsk-mazowiecki",
    title: "Tworzenie stron sklepów aplikacji internetowych Mińsk Mazowiecki",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-olsztyn",
    title: "Tworzenie stron sklepów aplikacji internetowych Olsztyn",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-ostroda",
    title: "Tworzenie stron sklepów aplikacji internetowych Ostróda",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-ostrow-wielkopolski",
    title:
      "Tworzenie stron sklepów aplikacji internetowych Ostrów Wielkopolski",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-piekary-slaskie",
    title: "Tworzenie stron sklepów aplikacji internetowych Piekary Śląskie",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-piotrkow-trybunalski",
    title:
      "Tworzenie stron sklepów aplikacji internetowych Piotrków Trybunalski",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-plock",
    title: "Tworzenie stron sklepów aplikacji internetowych Płock",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-poznan",
    title: "Tworzenie stron sklepów aplikacji internetowych Poznań",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-pruszcz-gdanski",
    title: "Tworzenie stron sklepów aplikacji internetowych Pruszcz Gdański",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-radom",
    title: "Tworzenie stron sklepów aplikacji internetowych Radom",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-reda",
    title: "Tworzenie stron sklepów aplikacji internetowych Reda",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-ruda-slaska",
    title: "Tworzenie stron sklepów aplikacji internetowych Ruda Śląska",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-rybnik",
    title: "Tworzenie stron sklepów aplikacji internetowych Rybnik",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-rzeszow",
    title: "Tworzenie stron sklepów aplikacji internetowych Rzeszów",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-siedlce",
    title: "Tworzenie stron sklepów aplikacji internetowych Siedlce",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-siemianowice-slaskie",
    title:
      "Tworzenie stron sklepów aplikacji internetowych Siemianowice Śląskie",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-skierniewice",
    title: "Tworzenie stron sklepów aplikacji internetowych Skierniewice",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-slupsk",
    title: "Tworzenie stron sklepów aplikacji internetowych Słupsk",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-sopot",
    title: "Tworzenie stron sklepów aplikacji internetowych Sopot",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-sosnowiec",
    title: "Tworzenie stron sklepów aplikacji internetowych Sosnowiec",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-swidnica",
    title: "Tworzenie stron sklepów aplikacji internetowych Świdnica",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-szczecin",
    title: "Tworzenie stron sklepów aplikacji internetowych Szczecin",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-tarnow",
    title: "Tworzenie stron sklepów aplikacji internetowych Tarnow",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-tarnowskie-gory",
    title: "Tworzenie stron sklepów aplikacji internetowych Tarnowskie Góry",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-torun",
    title: "Tworzenie stron sklepów aplikacji internetowych Toruń",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-trojmiasto",
    title: "Tworzenie stron sklepów aplikacji internetowych Trójmiasto",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-tychy",
    title: "Tworzenie stron sklepów aplikacji internetowych Tychy",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-walbrzych",
    title: "Tworzenie stron sklepów aplikacji internetowych Wałbrzych",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa",
    title: "Tworzenie stron sklepów aplikacji internetowych Warszawa",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-bialoleka",
    title: "Tworzenie stron sklepów aplikacji internetowych Warszawa Białołęka",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-mokotow",
    title: "Tworzenie stron sklepów aplikacji internetowych Warszawa Mokotów",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-praga-poludnie",
    title:
      "Tworzenie stron sklepów aplikacji internetowych Warszawa Praga Południe",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-ursynow",
    title: "Tworzenie stron sklepów aplikacji internetowych Warszawa Ursynów",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-wawer",
    title: "Tworzenie stron sklepów aplikacji internetowych Warszawa Wawer",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-wejherowo",
    title: "Tworzenie stron sklepów aplikacji internetowych Wejherowo",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-wloclawek",
    title: "Tworzenie stron sklepów aplikacji internetowych Włocławek",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-wroclaw",
    title: "Tworzenie stron sklepów aplikacji internetowych Wrocław",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-zakopane",
    title: "Tworzenie stron sklepów aplikacji internetowych Zakopane",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-zawiercie",
    title: "Tworzenie stron sklepów aplikacji internetowych Zawiercie",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-zielona-gora",
    title: "Tworzenie stron sklepów aplikacji internetowych Zielona Góra",
  },
  {
    path: "/tworzenie-stron-sklepow-aplikacji-internetowych-zyradow",
    title: "Tworzenie stron sklepów aplikacji internetowych Żyradów",
  },
];

function App() {
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <div className="App">
        <BrowserRouter
          future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true,
          }}
        >
          {" "}
          <Routes>
            <Route>
              {/* ================ PODSTAWOWE ================ */}
              <Route path="/" element={<Home />} />
              <Route path="/o-nas" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/bezplatna-konsultacja" element={<Contact />} />
              <Route path="/program-partnerski" element={<Partner />} />
              <Route path="/wycena" element={<PricingQuote />} />
              <Route path="/brief" element={<Brief />} />
              <Route path="/dziekujemy" element={<ThankForm />} />
              {/* ================ DODATKOWE ================ */}
              <Route path="/rozwiazujemy-problemy" element={<Problems />} />
              {/* ================ OFERTA ================ */}
              <Route path="/oferta" element={<Offer />} />
              <Route path="/aplikacje" element={<Apps />} />
              <Route path="/sklepy-internetowe" element={<Shops />} />
              <Route path="/strony-internetowe" element={<Websites />} />
              <Route path="/systemy-crm" element={<Crm />} />
              <Route path="/integracje-ai" element={<Ai />} />\
              <Route path="/wizytowki-firmowe" element={<CompanyWebsites />} />
              <Route path="/minimum-viable-product" element={<Mvp />} />
              <Route path="/odswiezenie-wizerunku" element={<Refresh />} />
              <Route
                path="/projektowanie-interfejsow"
                element={<Interfaces />}
              />
              <Route path="/systemy-rezerwacji" element={<Reservation />} />
              <Route path="/automatyzacja-procesow" element={<Automation />} />
              {/* ================ PORADNIKI ================ */}
              <Route path="/pomoc" element={<Support />} />
              <Route path="/pomoc/wsparcie" element={<SupportTicket />} />
              <Route path="/pomoc/baza-wiedzy" element={<SupportBlog />} />
              <Route
                path="/pomoc/baza-wiedzy/:categorySlug/:postSlug"
                element={<SupportBlogPost />}
              />
              <Route
                path="/pomoc/baza-wiedzy/:categorySlug"
                element={<SupportBlogCategory />}
              />
              <Route
                path="/pomoc/baza-wiedzy/:categorySlug/modul/:subcategorySlug"
                element={<SupportBlogSubcategory />}
              />
              {/* ================ DOKUMENTY ================ */}
              <Route
                path="/dokumenty/regulamin-serwisu"
                element={<DocumentsTerms />}
              />
              <Route
                path="/dokumenty/polityka-prywatnosci"
                element={<DocumentsPolicy />}
              />
              <Route
                path="/dokumenty/obowiazek-informacyjny"
                element={<DocumentsInformation />}
              />
              <Route
                path="/dokumenty/program-partnerski"
                element={<DocumentsPartner />}
              />
              {/* ================ PANEL KLIENTA ================ */}
              <Route path="/panel/login" element={<AccountLogin />} />
              <Route path="/panel/rejestracja" element={<AccountRegister />} />
              <Route
                path="/panel/"
                element={
                  <ProtectedRoute>
                    <AccountPanel />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/panel/nowe-zgloszenie"
                element={
                  <ProtectedRoute>
                    <AccountNewTicket />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/panel/umow-spotkanie"
                element={
                  <ProtectedRoute>
                    <AccountBooking />
                  </ProtectedRoute>
                }
              />
              {/* ================ PANEL PRACOWNIKA ================ */}
              <Route path="/hcrtnbdscmyvyu" element={<Employee />} />
              {/* ================ OFERTY ================ */}
              <Route
                path="/letsoffer/websiteoffer/:offerSlug"
                element={<LetsOfferWebsite />}
              />
              <Route
                path="/letsoffer/shopoffer/:offerSlug"
                element={<LetsOfferShop />}
              />
              <Route
                path="/letsoffer/appoffer/:offerSlug"
                element={<LetsOfferApp />}
              />
              {/* ================ 404 ================ */}
              <Route path="*" element={<PageNotFound />} />
              {/* ================ SEO ================ */}
              {seoPages.map(({ path, title }) => (
                <Route
                  key={path}
                  path={path}
                  element={<SeoHome title={title} />}
                />
              ))}
              {/* ================ LANDING PAGES ================ */}
              <Route path="paynow" element={<LandingPayNow />} />
              {/* ================ REDIRECTIONS ================ */}
              <Route path="/ifil-web-design" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route
                path="/blog/ile-kosztuje-strona-internetowa"
                element={<SupportBlogPost />}
              />
              <Route
                path="/blog/ile-kosztuje-strona-internetowa/"
                element={<SupportBlogPost />}
              />
              <Route
                path="/blog/web-core-vitals"
                element={<SupportBlogPost />}
              />
              <Route
                path="/blog/web-core-vitals/"
                element={<SupportBlogPost />}
              />
              <Route path="/blog/" element={<SupportBlogPost />} />
              <Route path="/blog" element={<SupportBlogPost />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
