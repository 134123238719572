import BackgroundSquare from "../common/squareBg";

function ModernSolutions() {
  return (
    <>
      <section className="blackColorBg hidden lg:flex flex-col items-center justify-end gap-12 pt-24 relative z-20 overflow-hidden">
        <div className="absolute w-full h-full z-10">
          <BackgroundSquare />
        </div>
        <div className="mainLight w-full h-64 translate-y-64 absolute bottom-0 z-10"></div>
        <h2 className="text-6xl whiteHeading text-center z-20">
          Nowoczesne rozwiązania dla
          <br />
          <span className="colorHeading fontRegular">lepszej współpracy</span>
        </h2>
        <div className="px-12 grid grid-cols-2 gap-12 pt-12 z-20">
          <div className="flex flex-col items-center justify-end gap-6">
            <h5 className="text-4xl whiteHeading">Autenti</h5>
            <p className="text-white text-xl opacity-60 mb-6 w-3/4 text-center">
              Dysponujemy platformą do podpisywania umów na odległość. Dzięki
              temu podpiszesz umowę z dowolnego miejsca na ziemi, z pełną mocą
              prawną.
            </p>
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/solutions-1.webp"
              alt=""
              className="rounded-t-2xl"
            />
          </div>
          <div className="flex flex-col items-center justify-end gap-6">
            <h5 className="text-4xl whiteHeading">Panel Klienta iFil</h5>
            <p className="text-white text-xl opacity-60 mb-6 w-3/4 text-center">
              Autorskie oprogramowanie do zarządzania projektem oraz
              zgłoszeniami dyspozycji. Dzięki niemu masz pełną kontrolę nad
              realizacją Twoich zgłoszeń.
            </p>
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/solutions-2.webp"
              alt=""
              className="rounded-t-2xl"
            />
          </div>
        </div>
      </section>
      <section className="blackColorBg lg:hidden flex flex-col items-center justify-end gap-12 pt-12 px-6 lg:pt-24 relative z-20 overflow-hidden">
        <div className="absolute w-full h-full z-10">
          <BackgroundSquare />
        </div>
        <div className="mainLight w-full h-64 translate-y-64 absolute bottom-0 z-10"></div>
        <h2 className="text-3xl lg:text-6xl whiteHeading text-center z-20">
          Nowoczesne rozwiązania dla
          <br />
          <span className="colorHeading fontRegular">lepszej współpracy</span>
        </h2>
        <div className="px-6 lg:px-12 grid grid-cols-1 lg:grid-cols-2 gap-12 z-20">
          <div className="flex flex-col items-center justify-end gap-6">
            <h5 className="text-2xl lg:text-4xl whiteHeading">Autenti</h5>
            <p className="text-white text-xl opacity-60 mb-6 w-full lg:w-3/4 text-center">
              Dysponujemy platformą do podpisywania umów na odległość. Dzięki
              temu podpiszesz umowę z dowolnego miejsca na ziemi, z pełną mocą
              prawną.
            </p>
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/solutions-1.webp"
              alt=""
              className="rounded-t-2xl"
            />
          </div>
          <div className="flex flex-col items-center justify-end gap-6">
            <h5 className="text-2xl lg:text-4xl whiteHeading">
              Panel Klienta iFil
            </h5>
            <p className="text-white text-xl opacity-60 mb-6 w-full lg:w-3/4 text-center">
              Autorskie oprogramowanie do zarządzania projektem oraz
              zgłoszeniami dyspozycji. Dzięki niemu masz pełną kontrolę nad
              realizacją Twoich zgłoszeń.
            </p>
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/solutions-2.webp"
              alt=""
              className="rounded-t-2xl"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default ModernSolutions;
