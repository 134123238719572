import { Helmet } from "react-helmet-async";
function Employee() {
  return (
    <>
      <Helmet>
        <title>iFil Group</title>
        <meta name="title" content="iFil Group" />
        <meta
          name="description"
          content="Integracje systemów, aplikacji, stron i sklepów internetowych z wykorzystaniem sztucznej inteligencji, OpenAI oraz ChatGPT."
          key="desc"
        />
        <meta property="og:title" content="iFil Group" />
        <meta
          property="og:description"
          content="Integracje systemów, aplikacji, stron i sklepów internetowych z wykorzystaniem sztucznej inteligencji, OpenAI oraz ChatGPT."
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <section className="w-full h-screen blackColorBg grid grid-cols-1 lg:grid-cols-6 p-2 lg:p-6 gap-2">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://outlook.office.com/calendar/view/month"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-ms-outlook.png"
            alt=""
          />
          <p className="text-2xl text-white">MS Outlook</p>
          <p className="mainColor">E-mail</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://outlook.office.com/mail/"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-ms-outlook.png"
            alt=""
          />
          <p className="text-2xl text-white">MS Outlook</p>
          <p className="mainColor">Calendar</p>{" "}
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://ifilgroup2.pipedrive.com/pipeline"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-pipedrive.png"
            alt=""
          />
          <p className="text-2xl text-white">Pipedrive</p>
          <p className="mainColor">CRM</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://app.clickup.com/9015161037/v/l/7-9015161037-1"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-clickup.png"
            alt=""
          />
          <p className="text-2xl text-white">ClickUp</p>
          <p className="mainColor">Management</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://ifilgroup.sharepoint.com/sites/ifilgroupemployees/Shared%20Documents/Forms/AllItems.aspx"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-sharepoint.png"
            alt=""
          />
          <p className="text-2xl text-white">MS SharePoint</p>
          <p className="mainColor">Drive</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://konto.infakt.pl/?_gl=1*1pph5f0*_ga*MjcxNjQ4MTQxLjE3MTA3NzAwMTI.*_ga_F558C6KWSJ*MTcxMjg1NTY3MS4yNy4wLjE3MTI4NTU2NzEuNjAuMC4w"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-infakt.png"
            alt=""
          />
          <p className="text-2xl text-white">InFakt</p>
          <p className="mainColor">Accounting</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://account.autenti.com/login?lang=pl"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-autenti.png"
            alt=""
          />
          <p className="text-2xl text-white">Autenti</p>
          <p className="mainColor">Agreements</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://app.slack.com/client/T06TX0VRC1Z/C06TU6ZFJAH"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-slack.png"
            alt=""
          />
          <p className="text-2xl text-white">Slack</p>
          <p className="mainColor">Chat</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://outlook.office.com/bookings/"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-ms-bookings.png"
            alt=""
          />
          <p className="text-2xl text-white">MS Bookings</p>
          <p className="mainColor">Bookings</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://zapier.com/app/dashboard"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6 bg-white rounded-full"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-zapier.png"
            alt=""
          />
          <p className="text-2xl text-white">Zapier</p>
          <p className="mainColor">Automation</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://cloud.digitalocean.com/"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-digitalocean.png"
            alt=""
          />
          <p className="text-2xl text-white">Digital Ocean</p>
          <p className="mainColor">Hosting</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://github.com"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/logo-github.png"
            alt=""
          />
          <p className="text-2xl text-white">GitHub</p>
          <p className="mainColor">Repository</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://app.apollo.io/#/login"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/icon-apollo.png"
            alt=""
          />
          <p className="text-2xl text-white">Apollo</p>
          <p className="mainColor">Prospecting</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.tidio.com/panel/inbox"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/icon-tidio.png"
            alt=""
          />
          <p className="text-2xl text-white">Tidio</p>
          <p className="mainColor">Live Chat</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://fireflies.ai/"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/icon-fireflies-ai.png"
            alt=""
          />
          <p className="text-2xl text-white">Fireflies AI</p>
          <p className="mainColor">Meeting Notes</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://teams.microsoft.com/"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/icon-teams.png"
            alt=""
          />
          <p className="text-2xl text-white">MS Teams</p>
          <p className="mainColor">Video Meetings</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://vault.bitwarden.com/#/login"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/icon-bitwarden.png"
            alt=""
          />
          <p className="text-2xl text-white">Bitwarden</p>
          <p className="mainColor">Password Manager</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://loom.com/"
          className="flex flex-col items-center justify-center lightWhite hover:mainColorBg transition duration-300 ease-linear p-2 rounded-xl"
        >
          <img
            className="w-24 mb-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/tools-logo/icon-loom.png"
            alt=""
          />
          <p className="text-2xl text-white">Loom</p>
          <p className="mainColor">Videos</p>
        </a>
      </section>
    </>
  );
}
export default Employee;
