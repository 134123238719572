import { motion, useScroll, useTransform } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

function Problems() {
  const { scrollYProgress } = useScroll();
  const [activeProblem, setActiveProblem] = useState<null | number>(null);

  const zIndex1 = useTransform(scrollYProgress, [0.23, 0.25], [50, 50], {});
  const zIndex2 = useTransform(scrollYProgress, [0.25, 0.254], [0, 200]);
  const zIndex3 = useTransform(scrollYProgress, [0.254, 0.29], [0, 400], {});

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.15,
  });

  const scale1 = useTransform(
    scrollYProgress,
    [0.23, 0.25],
    inView ? [0, 1] : [0, 0]
  );

  const scale2 = useTransform(
    scrollYProgress,
    [0.25, 0.27],
    inView ? [0, 1] : [0, 0]
  );

  const scale3 = useTransform(
    scrollYProgress,
    [0.27, 0.29],
    inView ? [0, 1] : [0, 0]
  );

  useEffect(() => {
    const unsubscribe = scrollYProgress.on("change", (value) => {
      if (value >= 0 && value < 0.25) {
        setActiveProblem(1);
      } else if (value >= 0.25 && value < 0.27) {
        setActiveProblem(2);
      } else if (value >= 0.27 && value <= 1) {
        setActiveProblem(3);
      } else {
        setActiveProblem(null);
      }
    });

    // Zwrócenie funkcji czyszczącej
    return () => unsubscribe();
  }, [scrollYProgress]);

  return (
    <>
      <section
        className="hidden lg:flex blackColorBg relative w-full z-50"
        style={{ height: "350vh" }}
      >
        <div className="sticky top-0 flex flex-row items-center justify-end h-screen gap-12 w-full">
          <div className="w-1/2 flex flex-col gap-2 items-start justify-center">
            <p className="text-white px-6 rounded-xl py-1 mixedGradients">
              Jakie problemy rozwiązujemy
            </p>
            <h2 className="text-5xl text-left mb-6">
              <span className="whiteHeading fontRegular">
                Z jakimi problemami mierzą się nasi
              </span>{" "}
              <span className="colorHeading fontRegular">przyszli Klienci</span>
              <span className="whiteHeading fontRegular">?</span>
            </h2>
            <div
              className={`w-full h-full flex gap-4 flex-row items-center mb-2 relative transition duration-300 ease-in-out ${
                activeProblem === 1 ? "opacity-100" : "opacity-30"
              }`}
            >
              <div className="w-4 rounded-xl h-[9rem] relative flex flex-col items-start justify-start grayColor">
                <motion.div
                  className="progress-bar rounded-xl"
                  style={{ scaleY: scale1 }}
                  ref={ref}
                />
              </div>
              <div className="flex flex-col gap-1">
                <h4
                  className={`w-full ${
                    activeProblem === 1
                      ? "mainColor text-2xl"
                      : "text-white text-2xl"
                  }`}
                >
                  Nieatrakcyjny interfejs
                </h4>
                <p className="text-white w-2/3 mt-2 opacity-90 ">
                  Tworzymy interfejsy, które nie tylko przyciągają uwagę
                  estetyką, ale także kładą nacisk na aspekty biznesowe, mające
                  na celu zwiększenie konwersji.
                </p>
              </div>
            </div>
            <div
              className={`w-full h-full flex gap-4 flex-row items-center mb-2 relative transition duration-300 ease-in-out ${
                activeProblem === 2 ? "opacity-100" : "opacity-30"
              }`}
            >
              <div className="w-4 rounded-xl h-[9rem] relative flex flex-col items-start justify-start grayColor">
                <motion.div
                  className="progress-bar rounded-xl"
                  style={{ scaleY: scale2 }}
                  ref={ref}
                />
              </div>
              <div className="flex flex-col gap-1">
                <h4
                  className={`w-full ${
                    activeProblem === 2
                      ? "mainColor text-2xl"
                      : "text-white text-2xl"
                  }`}
                >
                  Niska wydajność
                </h4>
                <p className="text-white w-2/3 mt-2 opacity-90 ">
                  Nasze rozwiązania są dostosowane do obecnych standardów,
                  eliminując opóźnienia i poprawiając doświadczenia
                  użytkowników, jednocześnie wspierając widoczność w
                  wyszukiwarkach.
                </p>
              </div>
            </div>
            <div
              className={`w-full h-full flex gap-4 flex-row items-center mb-6 relative transition duration-300 ease-in-out ${
                activeProblem === 3 ? "opacity-100" : "opacity-30"
              }`}
            >
              <div className="w-4 rounded-xl h-[9rem] relative flex flex-col items-start justify-start grayColor">
                <motion.div
                  className="progress-bar rounded-xl"
                  style={{ scaleY: scale3 }}
                  ref={ref}
                />
              </div>
              <div className="flex flex-col gap-1">
                <h4
                  className={`w-full ${
                    activeProblem === 3
                      ? "mainColor text-2xl"
                      : "text-white text-2xl"
                  }`}
                >
                  Brak automatyzacji
                </h4>
                <p className="text-white w-2/3 mt-2 opacity-90 ">
                  Wprowadzamy systemy, które uwalniają Cię od monotonnych zadań.
                  Nasze aplikacje są zaprojektowane z myślą o automatyzacji
                  procesów, dzięki czemu możesz skoncentrować się na innych
                  aspektach swojego biznesu.
                </p>
              </div>
            </div>
            <a href="/rozwiazujemy-problemy">
              <button className="firstButton">Poznaj więcej przykładów</button>
            </a>
          </div>
          <div className="w-2/5 relative flex flex-col items-center justify-center">
            <div
              style={{ zIndex: "99999999" }}
              className="absolute bottom-48 lightWhite right-12 px-6 w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow"
            >
              <p className="text-center">Nasze rozwiązanie</p>
            </div>
            <motion.img
              className="rounded-l-xl firstShadow absolute left-0"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/interfejs.png"
              alt=""
              style={{ zIndex: zIndex1 }}
            />
            <motion.img
              className="rounded-l-xl firstShadow absolute left-0"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/wydajnosc.png"
              alt=""
              style={{ zIndex: zIndex2 }}
            />
            <motion.img
              className="rounded-l-xl firstShadow absolute left-0"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/automatyzacje.png"
              alt=""
              style={{ zIndex: zIndex3 }}
            />
          </div>
        </div>
      </section>
      <section className="lg:hidden flex flex-col items-start justify-start p-6 blackColorBg">
        <p className="text-white px-6 rounded-xl py-1 mixedGradients mb-6">
          Jakie problemy rozwiązujemy
        </p>
        <h2 className="text-3xl lg:text-6xl whiteHeading text-left">
          Z jakimi problemami mierzą się nasi{" "}
          <span className="colorHeading fontRegular">przyszli Klienci</span>?
        </h2>
        <span className="mainColorBg w-6 h-1 mt-6 mb-2"></span>
        <h4 className="text-white text-2xl">Nieatrakcyjny interfejs</h4>
        <p className="text-white w-full mt-2 opacity-90 ">
          Tworzymy interfejsy, które nie tylko przyciągają uwagę estetyką, ale
          także kładą nacisk na aspekty biznesowe, mające na celu zwiększenie
          konwersji.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/interfejs.png"
          alt=""
          className="mt-6 firstShadowLight rounded-xl"
        />
        <span className="mainColorBg w-6 h-1 mt-6 mb-2"></span>
        <h4 className="text-white text-2xl">Niska wydajność</h4>
        <p className="text-white w-full mt-2 opacity-90 ">
          Nasze rozwiązania są dostosowane do obecnych standardów, eliminując
          opóźnienia i poprawiając doświadczenia użytkowników, jednocześnie
          wspierając widoczność w wyszukiwarkach.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/wydajnosc.png"
          alt=""
          className="mt-6 firstShadowLight rounded-xl"
        />
        <span className="mainColorBg w-6 h-1 mt-6 mb-2"></span>
        <h4 className="text-white text-2xl">Brak automatyzacji</h4>
        <p className="text-white w-full mt-2 opacity-90 ">
          Wprowadzamy systemy, które uwalniają Cię od monotonnych zadań. Nasze
          aplikacje są zaprojektowane z myślą o automatyzacji procesów, dzięki
          czemu możesz skoncentrować się na innych aspektach swojego biznesu.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/automatyzacje.png"
          alt=""
          className="mt-6 firstShadowLight rounded-xl"
        />
        <a className="mt-6 w-full" href="/rozwiazujemy-problemy">
          <button className="firstButton w-full">
            Poznaj więcej przykładów
          </button>
        </a>
      </section>
    </>
  );
}

export default Problems;
