import FooterForm from "../forms/footerForm";
function Footer() {
  const height = {
    height: "1px",
  };
  return (
    <footer
      className="blackColorBg flex flex-col items-start justify-center overflow-hidden"
      style={{ zIndex: "9999999999999999" }}
    >
      <div className="flex flex-col blackColorBg z-50">
        <div className="flex flex-col lg:flex-row items-center justify-center px-6 lg:px-24 gap-12 py-12 lg:py-24 relative w-full">
          <div className="w-full flex flex-col">
            <h2 className="text-3xl lg:text-6xl text-left text-wrap mb-6">
              <span className="whiteHeading fontRegular">
                Rozwiejmy wspólnie wątpliwości dotyczące
              </span>
              <br />
              <span className="colorHeading fontRegular">Twojego projektu</span>
            </h2>

            <p className="text-white text-xl lg:text-2xl opacity-60 mb-6 text-left">
              Skontaktujemy się z Tobą celem poznania Twoich potrzeb oraz
              przygotowania oferty projektu, który je spełni.
            </p>
            <a href="/bezplatna-konsultacja">
              <button className="firstButton flex flex-row items-center justify-center gap-1">
                <span>Skorzystaj z naszego doświadczenia</span>
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                      stroke="#ffffff"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                    ></path>{" "}
                  </g>
                </svg>
              </button>
            </a>
          </div>
          <div className="flex flex-col gap-6 w-full">
            <div className="w-full blackColorBg p-6 lg:p-12 firstShadowLight rounded-xl fancy relative flex flex-col items-center justify-center">
              <div className="w-5/6 z-10">
                <h2 className="text-4xl text-white mb-6">
                  Wypełnij{" "}
                  <span className="colorHeading fontRegular">formularz</span>
                </h2>
                <FooterForm />
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 lg:px-24 py-12 w-full flex flex-col lg:flex-row items-center justify-center lg:justify-between mixedGradients z-50">
          <h2 className="text-xl lg:text-4xl text-white text-center lg:text-left mb-6 lg:mb-0">
            Skontaktuj się z nami bezpośrednio
          </h2>
          <div className="flex flex-col lg:flex-row items-center justify-end lg:gap-6">
            <a
              className="text-white text-2xl lg:text-4xl flex flex-row items-center justify-center gap-2"
              href="mailto:hello@ifil.pl"
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4 18L9 12M20 18L15 12M3 8L10.225 12.8166C10.8665 13.2443 11.1872 13.4582 11.5339 13.5412C11.8403 13.6147 12.1597 13.6147 12.4661 13.5412C12.8128 13.4582 13.1335 13.2443 13.775 12.8166L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                </g>
              </svg>
              <span>hello@ifil.pl</span>
            </a>
            <a
              className="text-white text-2xl lg:text-4xl flex flex-row items-center justify-center gap-2"
              href="tel:+48515516387"
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
              <span>+48 515 516 387</span>
            </a>
          </div>
        </div>
      </div>
      <div className="blackColorBg z-50 w-full py-12 px-6 lg:p-24 blackColorBg flex flex-col lg:flex-row items-start justify-between footerShadow">
        <div className="w-full lg:w-1/2 flex flex-col gap-6">
          <img
            className="w-2/3"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/logo-white.png"
            alt=""
          />
          <div className="flex flex-col gap-1 opacity-60">
            <p className="text-white">iFil Group</p>
            <p className="text-white">NIP: PL8943219325</p>
            <p className="text-white">REGON: 526343129</p>
          </div>
          <div className="flex flex-row items-start justify-start gap-2">
            <a href="https://facebook.com/ifil.group">
              <div className="footerSocial">
                <svg
                  width="25"
                  height="25"
                  viewBox="-5 0 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>facebook [#176]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-385.000000, -7399.000000)"
                        fill="#ffffff"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                            id="facebook-[#176]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </div>
            </a>
            <a href="https://www.instagram.com/ifil.group/">
              <div className="footerSocial">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                      fill="#ffffff"
                    ></path>{" "}
                    <path
                      d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                      fill="#ffffff"
                    ></path>{" "}
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                      fill="#ffffff"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
            </a>
          </div>
          <a href="/bezplatna-konsultacja">
            <button className="firstButton flex flex-row items-center justify-center gap-1">
              <span>Bezpłatna konsultacja</span>
              <svg
                width={25}
                height={25}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                    stroke="#ffffff"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </a>
        </div>
        <div className="w-full flex flex-col lg:flex-row items-start justify-end gap-6 lg:gap-12 mt-12 lg:mt-0">
          <div className="flex flex-col">
            <p className="text-white text-2xl mb-4">Przydatne linki</p>
            <a className="footerLink" href="/pomoc/baza-wiedzy">
              Baza wiedzy
            </a>
            <a className="footerLink" href="/pomoc">
              Pomoc i poradniki
            </a>
            <a className="footerLink" href="/wycena">
              Formularz wyceny
            </a>
            <a className="footerLink" href="/brief">
              Brief projektowy
            </a>
            <a className="footerLink" href="/portfolio">
              Nasze realizacje
            </a>
          </div>
          <div className="flex flex-col">
            <p className="text-white text-2xl mb-4">Nasze usługi</p>
            <a className="footerLink" href="/strony-internetowe">
              Tworzenie stron internetowych
            </a>
            <a className="footerLink" href="/sklepy-internetowe">
              Tworzenie sklepów internetowych
            </a>
            <a className="footerLink" href="/aplikacje">
              Aplikacje internetowe
            </a>
            <a className="footerLink" href="/systemy-crm">
              Systemy CRM
            </a>
            <a className="footerLink" href="/integracje-ai">
              Integracje AI
            </a>
            <a className="footerLink" href="/wizytowki-firmowe">
              Wizytówki firmowe
            </a>
            <a className="footerLink" href="/minimum-viable-product">
              MVP
            </a>
            <a className="footerLink" href="/odswiezenie-wizerunku">
              Odświeżenie wizerunku
            </a>
            <a className="footerLink" href="/projektowanie-interfejsow">
              Projektowanie interfejsów
            </a>
            <a className="footerLink" href="/systemy-rezerwacji">
              Systemy rezerwacji
            </a>
            <a className="footerLink" href="/automatyzacja-procesow">
              Automatyzacja procesów
            </a>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col mb-4">
              <p className="text-white text-2xl mb-4">Informacje</p>
              <a className="footerLink" href="/o-nas">
                O nas
              </a>
              <a className="footerLink" href="/dokumenty/polityka-prywatnosci">
                Polityka prywatności
              </a>
              <a className="footerLink" href="/dokumenty/regulamin-serwisu">
                Regulamin serwisu
              </a>
              <a
                className="footerLink"
                href="/dokumenty/obowiazek-informacyjny"
              >
                Obowiązek informacyjny
              </a>
              <a href="/dokumenty/program-partnerski">
                <span className="footerLink">
                  Regulamin programu partnerskiego
                </span>
              </a>
            </div>
            <div className="flex flex-col">
              <p className="text-white text-2xl mb-4">Nasze produkty</p>
              <a className="footerLink" href="https://letsoffer.pl">
                Let's Offer
              </a>
              <a className="footerLink" href="https://managly.pl">
                Managly
              </a>
              <a className="footerLink" href="https://contractorflow.pl">
                Contractor Flow
              </a>
              {/* <a className="footerLink" href="https://porownajsolary.pl">
                Porównaj Solary
              </a> */}
              {/* <a className="footerLink" href="https://kasiatlumaczy.pl">
                Kasia tłumaczy
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="blackColorBg z-50 w-full px-6 lg:px-12 flex flex-col gap-6 blackColorBg pb-6">
        <span className="w-full bg-white opacity-50" style={height}></span>
        <div className="flex flex-row items-center justify-between">
          <a className="footerLink" href="https://ifil.pl">
            iFil Group | IT Solutions Provider
          </a>
          <a className="footerLink" href="https://filipkania.com">
            by Filip Kania
          </a>
        </div>
        <span className="w-full bg-white opacity-50" style={height}></span>
      </div>
      <div className="blackColorBg z-50 px-6 pb-6 lg:pb-12 lg:px-12 w-full gap-6 grid grid-cols-1 lg:grid-cols-7">
        <div className="flex flex-col gap-1">
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-bedzin"
          >
            Będzin
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-bialystok"
          >
            Białystok
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-bielany"
          >
            Bielany
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-bielsko-biala"
          >
            Bielsko Biała
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-bydgoszcz"
          >
            Bydgoszcz
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-bytom"
          >
            Bytom
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-chorzow"
          >
            Chorzów
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-czestochowa"
          >
            Częstochowa
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-dabrowa-gornicza"
          >
            Dąbrowa Górnicza
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-elblag"
          >
            Elbląg
          </a>
        </div>
        <div className="flex flex-col gap-1">
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-gdansk"
          >
            Gdańsk
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-gdynia"
          >
            Gdynia
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-gliwice"
          >
            Gliwice
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-gorzow-wielkopolski"
          >
            Gorzów Wielkopolski
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-jelenia-gora"
          >
            Jelenia Góra
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-kalisz"
          >
            Kalisz
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-katowice"
          >
            Katowice
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-kielce"
          >
            Kielce
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-kolobrzeg"
          >
            Kołobrzeg
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-konin"
          >
            Konin
          </a>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-konstancin"
          >
            Konstancin
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-koszalin"
          >
            Koszalin
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-krakow"
          >
            Kraków
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-lancut"
          >
            Łańcut
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-legnica"
          >
            Legnica
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-lodz"
          >
            Łódź
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-lowicz"
          >
            Łowicz
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-lubin"
          >
            Lubin
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-lublin"
          >
            Lublin
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-mikolow"
          >
            Mikołów
          </a>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-minsk-mazowiecki"
          >
            Mińsk Mazowiecki
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-olsztyn"
          >
            Olsztyn
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-ostroda"
          >
            Ostróda
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-ostrow-wielkopolski"
          >
            Ostrów Wielkopolski
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-piekary-slaskie"
          >
            Piekary Śląskie
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-piotrkow-trybunalski"
          >
            Piotrków Trybunalski
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-plock"
          >
            Płock
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-poznan"
          >
            Poznań
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-pruszcz-gdanski"
          >
            Pruszcz Gdański
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-radom"
          >
            Radom
          </a>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-reda"
          >
            Reda
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-ruda-slaska"
          >
            Ruda Śląska
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-rybnik"
          >
            Rybnik
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-rzeszow"
          >
            Rzeszów
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-siedlce"
          >
            Siedlce
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-siemianowice-slaskie"
          >
            Siemianowice Śląskie
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-skierniewice"
          >
            Skierniewice
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-slupsk"
          >
            Słupsk
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-sopot"
          >
            Sopot
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-sosnowiec"
          >
            Sosnowiec
          </a>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-swidnica"
          >
            Świdnica
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-tarnow"
          >
            Tarnów
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-tarnowskie-gory"
          >
            Tarnowskie Góry
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-torun"
          >
            Toruń
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-trojmiasto"
          >
            Trójmiasto
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-tychy"
          >
            Tychy
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-walbrzych"
          >
            Wałbrzych
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa"
          >
            Warszawa
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-bialoleka"
          >
            Warszawa Białołęka
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-mokotow"
          >
            Warszawa Mokotów
          </a>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-praga-poludnie"
          >
            Warszawa Praga Południe
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-ursynow"
          >
            Warszawa Ursynów
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-warszawa-wawer"
          >
            Warszawa Wawer
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-wejherowo"
          >
            Wejherowo
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-wloclawek"
          >
            Włocławek
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-wroclaw"
          >
            Wrocław
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-zakopane"
          >
            Zakopane
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-zawiercie"
          >
            Zawiecie
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-zielona-gora"
          >
            Zielona Góra
          </a>
          <a
            className="text-xs text-white opacity-70 hover:opacity-40"
            href="/tworzenie-stron-sklepow-aplikacji-internetowych-zyradow"
          >
            Żyradów
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
