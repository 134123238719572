import BackgroundSquare from "../common/squareBg";

function Hero() {
  return (
    <section className="blackColorBg flex flex-col items-center justify-start gap-12 overflow-hidden relative">
      <div className="w-full h-full object-cover absolute inset-0">
        {" "}
        <BackgroundSquare />
      </div>
      <div className="flex flex-col items-center justify-center px-6 pt-12 lg:pt-36 pb-6 z-20">
        <img
          className="flex lg:hidden w-1/2"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/logo-white.png"
          alt=""
        />
        <h1 className="mainColor fontLight text-sm lg:text-xl">
          iFil Group - Rozwiązujemy problemy
        </h1>
        <h2 className="text-3xl lg:text-7xl text-center mb-12">
          <span className="whiteHeading fontRegular">
            Rozwiązujemy problemy
          </span>
          <br />
          <span className="whiteHeading fontRegular">i rozwijamy</span>{" "}
          <span className="colorHeading fontRegular">Twój biznes</span>
        </h2>
        <div className="flex flex-wrap gap-2 items-center justify-center max-w-[1000px]">
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Koszty marketingowe
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Wizerunek marki
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Wydajność projektu
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Bezpieczeństwo danych
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Własność serwera i domeny
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            System zarządzania treścią
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Reakcja na incydenty
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Wsparcie przy obsłudze
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Konwersja na stronie
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Sprzedaż
          </p>
          <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
            Automatyzacje
          </p>
        </div>
      </div>
    </section>
  );
}
export default Hero;
