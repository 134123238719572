import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
import Ticket from "../components/support/ticket";

function SupportTicket() {
  return (
    <>
      <Helmet>
        <title>Poradniki i wsparcie | iFil Group</title>
        <meta name="title" content="Poradniki i wsparcie | iFil Group" />
        <meta
          name="description"
          content="Baza wiedzy, poradniki, blog, wsparcie techniczne - strony internetowe, sklepy on-line, aplikacje, integracje AI."
          key="desc"
        />
        <meta property="og:title" content="Poradniki i wsparcie | iFil Group" />
        <meta
          property="og:description"
          content="Baza wiedzy, poradniki, blog, wsparcie techniczne - strony internetowe, sklepy on-line, aplikacje, integracje AI."
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Ticket />
      </main>
      <Footer />
    </>
  );
}
export default SupportTicket;
