import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import BackgroundSquare from "../common/squareBg";
import { Helmet } from "react-helmet-async";
import BASE_URL from "../../config";

// Definicja interfejsów dla postów i subkategorii
interface Post {
  title: string;
  slug: string;
  image_url: string;
  timing: string;
  category_slug: string;
}

interface SubcategoryType {
  title: string;
  description: string;
  posts: Post[];
  meta_title: string;
  meta_description: string;
  category_title: string;
}

function Subcategory() {
  const [subcategory, setSubcategory] = useState<SubcategoryType | null>(null);
  const { categorySlug, subcategorySlug } = useParams<{
    categorySlug: string;
    subcategorySlug: string;
  }>();

  // Pobieranie danych subkategorii
  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/blog/category/${categorySlug}/subcategory/${subcategorySlug}/`
      )
      .then((res) => {
        console.log("Data from API:", res.data);
        setSubcategory(res.data);
      })
      .catch((err) => console.log(err));
  }, [categorySlug, subcategorySlug]);

  if (!subcategory) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{subcategory.meta_title}</title>
        <meta name="title" content={subcategory.meta_title} />
        <meta name="description" content={subcategory.meta_description} />
        <meta property="og:title" content={subcategory.meta_title} />
        <meta
          property="og:description"
          content={subcategory.meta_description}
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>

      {/* Tło i stylizacja */}
      <section className="blackColorBg relative flex min-h-screen">
        <div className="w-full h-full z-10 absolute">
          <div className="sticky top-0 w-full h-screen">
            <BackgroundSquare />
          </div>
        </div>

        <div className="flex flex-col z-50 px-6 py-12 lg:px-36 lg:pt-36 w-full gap-2 max-w-[1350px] mx-auto">
          {/* Tytuł subkategorii */}
          <h1 className="text-3xl lg:text-6xl text-white">
            {subcategory.title}
          </h1>
          <span className="h-1 w-12 mainColorBg my-2"></span>
          <div className="flex flex-row items-center justify-start gap-2 uppercase mainColor">
            <a
              href="/pomoc"
              className="hover:opacity-50 transition duration-300 ease-linear"
            >
              Pomoc
            </a>
            <span>/</span>
            <a
              href="/pomoc/baza-wiedzy"
              className="hover:opacity-50 transition duration-300 ease-linear"
            >
              Baza wiedzy
            </a>
            <span>/</span>
            <a
              href={`/pomoc/baza-wiedzy/${categorySlug}`}
              className="hover:opacity-50 transition duration-300 ease-linear"
            >
              {subcategory.category_title}
            </a>
            <span>/</span>
            <p className="hover:opacity-50 transition duration-300 ease-linear fontSemiBold">
              {subcategory.title}
            </p>
          </div>
          <p className="text-lg text-white opacity-60 w-full lg:w-1/2">
            {subcategory.description}
          </p>

          {/* Renderowanie postów */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6 lg:mt-12">
            {subcategory.posts.map((post) => (
              <Link
                key={post.slug}
                to={`/pomoc/baza-wiedzy/${post.category_slug}/${post.slug}`}
                className="border border-white border-opacity-10 cursor-pointer rounded-xl flex flex-col overflow-hidden relative hover:opacity-70 transition duration-300 ease-linear group"
              >
                <img src={post.image_url} alt={post.title} />
                <div className="p-6 flex flex-col gap-2 relative h-full">
                  <div className="w-full h-full bg-gradient-to-br from-transparent to-[#6f00d8] opacity-30 group-hover:opacity-80 transition duration-300 ease-linear absolute z-20 block top-0 left-0"></div>
                  <div className="w-full h-full blur-3xl absolute z-20 block"></div>
                  <h2 className="text-base text-white group-hover:underline z-30">
                    {post.title}
                  </h2>
                  <p className="text-white text-sm opacity-80 z-30 ">
                    {post.timing} min |{" "}
                    <span className="capitalize">{post.category_slug}</span>
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Subcategory;
