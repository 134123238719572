import { FlipWords } from "../additional/flipWords";

function Pricing() {
  const words = ["ryzyko", "koszty", "czas"];

  return (
    <div
      id="skad-wynika-cena"
      className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-6 text-white mt-12"
    >
      <div className="gap-2 flex-col flex text-xl w-full p-6 lg:pl-24 items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Skąd wynika cena?
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Uniknij niespodziewanych kosztów
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <div className="w-full flex flex-col lg:flex-row items-center justify-between gap-20 py-6">
          <div className="w-full flex flex-col items-center justify-center gap-16">
            <div className="w-full rounded-xl flex flex-col lg:flex-row relative z-50 overflow-hidden shadow-[5px_5px_0px_0px_rgba(109,40,217)] rotate-2 hover:rotate-0 transition duration-300 ease-linear">
              <div className="w-full h-full glassBox absolute top-0 left-0 z-10 opacity-50"></div>
              <div className="z-20 px-8 py-8 lg:py-4 flex flex-col lg:flex-row lg:items-center lg:justify-start gap-6 firstSvg">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/pricing-1.png"
                  alt=""
                  className="w-16"
                />
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="mainColor text-2xl fontRegular">Wydajność</p>
                  <p className="text-base text-white opacity-80">
                    Optymalizujemy Twoją stronę, aby ładowała się odpowiednio
                    szybko. Prędkość wpływa nie tylko na pozycjonowanie, ale
                    również sprzedaż.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full rounded-xl flex flex-col lg:flex-row  relative z-50 overflow-hidden shadow-[5px_5px_0px_0px_rgba(109,40,217)] -rotate-2 hover:rotate-0 transition duration-300 ease-linear">
              <div className="w-full h-full glassBox absolute top-0 left-0 z-10 opacity-50"></div>
              <div className="z-20 px-8 py-8 lg:py-4 flex flex-col lg:flex-row lg:items-center lg:justify-start gap-6 secondSvg">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/pricing-2.png"
                  alt=""
                  className="w-16"
                />
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="mainColor text-2xl fontRegular">
                    Pozycjonowanie
                  </p>
                  <p className="text-base text-white opacity-80">
                    Dbamy o techniczną stronę pozycjonowania Twojego projektu.
                    Zwiększy to szanse na poprawę pozycji Twojej marki w sieci.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full rounded-xl flex flex-col lg:flex-row  relative z-50 overflow-hidden shadow-[5px_5px_0px_0px_rgba(109,40,217)] rotate-2 hover:rotate-0 transition duration-300 ease-linear">
              <div className="w-full h-full glassBox absolute top-0 left-0 z-10 opacity-50"></div>
              <div className="z-20 px-8 py-8 lg:py-4 flex flex-col lg:flex-row lg:items-center lg:justify-start gap-6 thirdSvg">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/pricing-3.png"
                  alt=""
                  className="w-16"
                />
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="mainColor text-2xl fontRegular">Wsparcie</p>
                  <p className="text-base text-white opacity-80">
                    Oferujemy bezpłatne wsparcie powdrożeniowe dostępne za
                    pomocą maila, infolinii, czatu na żywo lub panelu Klienta
                    cały tydzień.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col">
            <p className="whiteHeading text-3xl lg:text-6xl fontRegular">
              Obniżamy <FlipWords words={words} />
            </p>
            <p className="whiteHeading text-2xl lg:text-5xl fontRegular">
              Nie płać{" "}
              <span className="colorHeading fontRegular">podwójnie</span>
            </p>
            <p className="text-white w-full mt-2 opacity-60 text-xl mb-6">
              Zdajemy sobie sprawę, że nie jesteśmy najtańszą opcją dostępną na
              rynku. Nasza cena poparta jest jednak kompleksowym procesem,
              którym kierujemy się podczas realizacji projektu informatycznego.
            </p>
            <p className="text-white w-full mt-2 opacity-60 text-xl mb-6">
              Znacząca ilość naszych Klientów rozpoczyna z nami współpracę z
              powodu problemów z obecnym wykonawcą. Projekt IT to nie tylko
              interfejs graficzny. Głównych problemów nie widać na pierwszy rzut
              oka! Są to m.in. niska wydajność, problemy z pozycjonowaniem oraz
              brak wsparcia powdrożeniowego.
            </p>
            <a href="#kontakt">
              <button className="firstButton">
                Zainteresowała mnie ta oferta
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pricing;
