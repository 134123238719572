import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";

import React, { ChangeEvent, useState } from "react";
import axios from "axios";
import BASE_URL from "../../config";
import { useNavigate } from "react-router-dom";
import { PricingProblemsModal } from "../modals/pricingProblems";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

interface OfferFormData {
  yourName: string;
  email: string;
  phoneNumber: string;
  currentWebsite: string;
  completionTimeframe: string;
  service: string;
  additionalInformation: string;
  target: string;
  problems: string;
  consent: boolean;
}

function OfferForm() {
  const outerTheme = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<OfferFormData>({
    yourName: "",
    email: "",
    phoneNumber: "",
    currentWebsite: "",
    completionTimeframe: "",
    service: "",
    additionalInformation: "",
    target: "",
    problems: "",
    consent: false,
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked?: boolean
  ) => {
    const isCheckbox = event.target.type === "checkbox";
    const { name } = event.target;
    const value = isCheckbox ? checked : event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await axios.post(`${BASE_URL}/api/mailing/offer-form/`, formData);
      navigate("/dziekujemy#top");
    } catch (error) {
      console.error("Error submitting the form: ", error);
      alert("Failed to submit the form");
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
        <span className="mainColorBg w-12 h-1"></span>
        <h2 className="text-white text-3xl">
          Dane <span className="mainColor fontRegular">kontaktowe</span>
        </h2>

        <TextField
          label="Imię i nazwisko / Nazwa firmy"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.yourName}
          onChange={handleChange}
          name="yourName"
        />
        <TextField
          label="Twój adres e-mail"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.email}
          onChange={handleChange}
          name="email"
        />
        <TextField
          label="Twój numer telefonu"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.phoneNumber}
          onChange={handleChange}
          name="phoneNumber"
        />

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Informacje dotyczące{" "}
          <span className="mainColor fontRegular">usługi</span>
        </h2>

        <FormControl fullWidth>
          <InputLabel id="service-label">Usługa</InputLabel>
          <Select
            labelId="service-label"
            id="service-select"
            value={formData.service}
            label="Usługa"
            onChange={handleSelectChange}
            name="service"
          >
            <MenuItem value="Strona internetowa">Strona internetowa</MenuItem>
            <MenuItem value="Sklep on-line">Sklep on-line</MenuItem>
            <MenuItem value="Projekt interfejsu graficznego">
              Projekt interfejsu graficznego
            </MenuItem>
            <MenuItem value="Aplikacja internetowa">
              Aplikacja internetowa
            </MenuItem>
            <MenuItem value="Integracja AI">Integracja AI</MenuItem>
            <MenuItem value="Aplikacja mobilna">Aplikacja mobilna</MenuItem>
            <MenuItem value="System CRM">System CRM</MenuItem>
            <MenuItem value="Automatyzacje">Automatyzacje</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Jaki termin realizacji Cię interesuje?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.completionTimeframe}
          onChange={handleChange}
          name="completionTimeframe"
        />

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Informacje dotyczące{" "}
          <span className="mainColor fontRegular">działalności</span>
        </h2>

        <TextField
          label="Jaki cel chcesz osiągnąć przez realizację projektu?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.target}
          onChange={handleChange}
          name="target"
        />

        <TextField
          label="Czy są jakieś problemy z jakimi mierzy się Twoja firma?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.problems}
          onChange={handleChange}
          name="problems"
        />

        <PricingProblemsModal />

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Dodatkowe <span className="mainColor fontRegular">informacje</span>
        </h2>

        <TextField
          label="Czy chcesz nam coś jeszcze przekazać?"
          variant="standard"
          fullWidth
          color="secondary"
          multiline
          rows={4}
          value={formData.additionalInformation}
          onChange={handleChange}
          name="additionalInformation"
        />

        <div className="flex flex-row items-start justify-start gap-2">
          <Checkbox
            checked={formData.consent}
            onChange={handleChange}
            name="consent"
            id="consent"
            required={true}
          />
          <label
            htmlFor="consent"
            className="text-xs opacity-50 mb-2 text-white"
          >
            Wyrażam zgodę na kontakt telefoniczny oraz mailowy celem realizacji
            niniejszego zapytania. Oświadczam, że zapoznałem/am się z
            Regulaminem Serwisu oraz Polityką Prywatności i akceptuję
            postanowienia nieniejszych dokumentów. Wyrażam zgodę na
            wykorzystanie adresu e-mail w celach marketingowych przez iFil
            Group.
          </label>
        </div>

        <button className="firstButton" type="submit">
          Wyślij
        </button>
      </form>
    </ThemeProvider>
  );
}

export default OfferForm;
