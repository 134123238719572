import Hero from "../components/home/hero";
import HowWeCanHelp from "../components/home/howWeCanHelp";
import WhyIfil from "../components/home/whyIfil";
import Problems from "../components/home/problems";
import Industry from "../components/home/industry";
import Letsoffer from "../components/home/letsoffer";
import HowWeWork from "../components/home/howWeWork";
import Cta from "../components/home/cta";
import Faq from "../components/home/faq";
import Support from "../components/home/support";
import Blog from "../components/home/blog";
import Testimonials from "../components/home/testimonials";
import LowerRisk from "../components/home/lowerRisk";
import { MacbookScrollDemo } from "../components/home/projectImage";
import Forest from "../components/home/forest";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
import Widget from "../components/chat/widget";
import Projects from "../components/home/projects";
export const products = [
  {
    title: "Let's Offer",
    link: "https://letsoffer.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-letsoffer-img.jpeg",
  },
  {
    title: "ByElementum",
    link: "https://byelementum.com",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/sm-byelementum.png",
  },
  {
    title: "Fundacja 'Młodzi Młodym'",
    link: "https://fsmm.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/lg-fsmm.png",
  },

  {
    title: "SoFollow",
    link: "https://sofollow.eu",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-sofollow-img.jpeg",
  },
  {
    title: "Artoni",
    link: "https://artoni.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-artoni-img.jpeg",
  },
  {
    title: "Romanik Tools",
    link: "https://romaniktools.eu",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/lg-romaniktools.png",
  },

  {
    title: "Flex Smart Systems",
    link: "https://flex.ae",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-flexae-img.jpeg",
  },
  {
    title: "Kasia Tłumaczy",
    link: "#",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-kasiatlumaczy-img.jpeg",
  },
  {
    title: "Carbone Performance",
    link: "https://carbone-performance.com",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-carboneperformance-img.jpeg",
  },
  {
    title: "Red Sky Blog",
    link: "https://red-sky.com/blog",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-redsky-img.jpeg",
  },
  {
    title: "Apteczka na szlaku",
    link: "https://apteczkanaszlaku.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-apteczkanaszlaku-img.jpeg",
  },
  {
    title: "Jak Leci",
    link: "https://jak-leci.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-jakleci-img.jpeg",
  },
  {
    title: "MTA Wedding",
    link: "https://mtawedding.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-mtawedding-img.jpeg",
  },
  {
    title: "E-Pojazd",
    link: "https://managly.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-managly-img.jpeg",
  },
  {
    title: "Boatman",
    link: "https://boatman.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-boatman-img.jpeg",
  },
];

function Home() {
  return (
    <>
      <Helmet>
        <title>
          Strony internetowe, sklepy on-line, aplikacje | iFil Group
        </title>
        <meta
          name="title"
          content="Strony internetowe, sklepy on-line, aplikacje | iFil Group"
        />
        <meta
          name="description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
          key="desc"
        />
        <meta
          property="og:title"
          content="Strony internetowe, sklepy on-line, aplikacje | iFil Group"
        />
        <meta
          property="og:description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        {/* <Widget /> */}
        <Hero />
        <HowWeCanHelp />
        <WhyIfil />
        <Problems />
        <LowerRisk />
        <Industry />
        <HowWeWork />
        <Projects products={products} />
        <Cta />
        <Letsoffer />
        <Faq />
        <MacbookScrollDemo />
        <Support />
        <Forest />
        <Testimonials />
        <Blog />
      </main>
      <Footer />
    </>
  );
}
export default Home;
