import { useState } from "react";

function Faq() {
  const initialQuestions = [
    {
      question:
        "Czy w cenie możemy liczyć na przygotowanie ewentualnego brandingu?",
      answer:
        "Tak, w ramach realizacji możemy określić kolorystykę, typografię oraz podstawowe logo.",
      isOpen: false,
    },
    {
      question: "Czy w cenie jest domena i serwer?",
      answer: "Tak, zapewniamy domenę oraz serwer na rok.",
      isOpen: false,
    },
    {
      question: "Jakie są dalsze koszty serwera i domeny?",
      answer:
        "Średni koszt utrzymania serwera u naszego Partnera to około 199 PLN netto. Koszt domeny oscyluje między 80 a 120 PLN netto. Pierwsza płatność pojawi się dopiero rok po podpisaniu umowy.",
      isOpen: false,
    },
    {
      question: "Kto będzie dbać o opłacanie serwera oraz domeny?",
      answer:
        "Dbając o niezależność naszych Klientów oraz ich pełną własność projektu Klient zobowiązany jest do uiszczania opłat za serwer oraz domenę. W tej sprawie będą Państwo otrzymywać regularne powiadomienia, aby nic Państwu nie umknęło.",
      isOpen: false,
    },
    {
      question: "Czy zapewniacie teksty i zdjęcia?",
      answer:
        "Tak, jeżeli nie dysponują Państwo materiałami przygotujemy dla Państwa nagłówki, opisy oraz zdjęcia i grafiki.",
      isOpen: false,
    },
    {
      question: "Jaki jest koszt dalszej obsługi?",
      answer:
        "Nie pobieramy opłat za wsparcie po realizacji projektu. Koszty związane z utrzymaniem projektu to serwer i domena. W przypadku chęci rozbudowy projektu o nowe funkcjonalności koszt ustalany jest indywidualnie. Na incydenty reagujemy maksymalnie do 24 godzin. Projekty powiązane są z naszym systemem reagowania. W przypadku, gdy Klient nie ingerował znacząco w projekt (modyfikując infrastrukturę, kod programistyczny) nie pobieramy żadnych opłat za naprawę projektu. W ramach realizacji świadczymy bezpłatne wsparcie techniczne dostępne za pośrednictwem poczty elektronicznej, infolinii, czatu na żywo oraz portalu Klienta.",
      isOpen: false,
    },
    {
      question: "Jakie są opłaty po realizacji projektu za ewentualne zmiany?",
      answer:
        "Drobne zmiany takie jak zmiana tekstu, grafiki bądź adresu mailowego realizujemy bezpłatnie do 3 dni roboczych od zgłoszenia. Zmiany wymagające dużego nakładu pracy, np. nowe funkcjonalności bądź przebudowa interfejsu graficznego rozliczane są indywidualnie.",
      isOpen: false,
    },
    {
      question: "Czy są kopie zapasowe?",
      answer:
        "Tak, nasz Partner dostarczający serwery wykonuje codzienne kopie zapasowe. Kopia zapasowa przechowywana jest do 30 dni.",
      isOpen: false,
    },
    {
      question:
        "Co się stanie, jeśli projekt przestanie działać lub się uszkodzi? Jakie są koszty naprawy?",
      answer:
        "Na incydenty reagujemy maksymalnie do 24 godzin. Projekty powiązane są z naszym systemem reagowania. W przypadku, gdy Klient nie ingerował znacząco w projekt (modyfikując infrastrukturę, kod programistyczny) nie pobieramy żadnych opłat za naprawę projektu. W ramach realizacji świadczymy bezpłatne wsparcie techniczne dostępne za pośrednictwem poczty elektronicznej, infolinii, czatu na żywo oraz portalu Klienta.",
      isOpen: false,
    },
    {
      question:
        "Co się stanie w przypadku ataku wirusów? Ile kosztuje naprawa?",
      answer:
        "Dostawca serwerów, z którym współpracujemy dysponuje kopią zapasową do 30 dni oraz certyfikacją ISO. Strona dodatkowo zabezpieczona będzie mechanizmami takimi jak reCAPTCHA, czy też Cross-Site Request Forgery Token. Minimalizuje to zagrożenie atakami na stronie internetowej. Odzyskanie kopii zapasowej oraz przywrócenie jej jest bezpłatne.",
      isOpen: false,
    },
    {
      question:
        "Co się stanie w przypadku ataku wirusów? Ile kosztuje naprawa?",
      answer:
        "Dostawca serwerów, z którym współpracujemy dysponuje kopią zapasową do 30 dni oraz certyfikacją ISO. Strona dodatkowo zabezpieczona będzie mechanizmami takimi jak reCAPTCHA, czy też Cross-Site Request Forgery Token. Minimalizuje to zagrożenie atakami na stronie internetowej. Odzyskanie kopii zapasowej oraz przywrócenie jej jest bezpłatne.",
      isOpen: false,
    },
    {
      question: "Czy jest gwarancja?",
      answer:
        "Tak, każdy projekt obejmowany jest 48-miesięczną gwarancją. Dodatkowo zapewniamy bezterminowe, bezpłatne wsparcie dostępne za pośrednictwem infolinii, maila oraz naszego autorskiego panelu Klienta.",
      isOpen: false,
    },
    {
      question: "Dlaczego wybrać iFil?",
      answer:
        "Zaopiekujemy każdy aspekt Twojego projektu informatycznego. Dbamy nie tylko o interfejs graficzny, czyli to co widzi użytkownik. Przeprowadzamy kompleksową optymalizację pod kątem wydajności, prędkości, dobrych praktyk, pozycjonowania technicznego oraz dostępności. Dysponujemy działem wsparcia dostępnym przez cały tydzień za pomocą maila i czatu na żywo, ale także pod infolinią od poniedziałku do piątku. Podczas realizacji projektu będziesz mieć kontakty jedynie ze swoim opiekunem projektu, dzięki temu unikniesz 'przerzucania' pomiędzy kilku współpracowników. Nie musisz martwić się o techniczne aspekty realizacji, zadbamy o wdrożenie Twojej realizacji do sieci oraz utrzymania jej na serwerze. Co więcej dysponujemy własną aplikacją do ofertowania Let's Offer, do której otrzymasz dożywotni dostęp bezpłatnie w ramach każdej realizacji. Let's Offer pozwoli Ci przenieść swoje oferty dla Klientów na wyższy poziom.",
      isOpen: false,
    },
    {
      question: "Dlaczego nie robimy projektów za 500, 1000, 1500 zł?",
      answer:
        "W sieci można znaleźć twórców stron, którzy podejmują się realizacji projektu za bardzo niskie stawki. W profesjonalny projekt zaangażowanych jest kilku specjalistów takich jak projektant interfejsu graficznego, specjalista UX oraz programista. W związku z tym nie ma możliwości utworzenia profesjonalnego projektu, który przyniesie korzyść Twojej marce za taką stawkę. Wielu twórców stron, sklepów oraz aplikacji internetowych nie dba o takie czynniki jak wydajność, dobre praktyki, pozycjonowanie oraz wsparcie powdrożeniowe. Podczas pracy nie korzystamy z gotowych motywów oraz narzędzki niskiej jakości, każdy projekt poprzedzony jest analizą oraz realizowany 'od zera'. Pamiętaj, że projekt informatyczny to nie tylko to co widzisz na pierwszy rzut oka!",
      isOpen: false,
    },
    {
      question: "Jaki jest limit podstron / zakładek?",
      answer:
        "Nie ma! Nie rozliczamy się na podstawie ilości podstron / zakładek. Zdajemy sobie sprawę, że projekt informatyczny składa się z wielu aspektów. W związku z tym jesteśmy elastyczni i w razie potrzeby tworzymy dodatkowe widoki. W naszych ofertach nie spotkasz się ze wskazaną liczbą podstron.",
      isOpen: false,
    },
    {
      question: "Jak wygląda wsparcie po projekcie?",
      answer:
        "W ramach każdego projektu - bez względu na cenę - zapewniamy bezterminowe wsparcie. Oznacza to, że zawsze możesz liczyć na naszą pomoc w razie jakichkolwiek problemów związanych z projektem. Wsparcie dostępne jest za pośrednictwem infolinii, maila oraz naszego autorskiego panelu Klienta.",
      isOpen: false,
    },
    {
      question: "Czy mogę dokonywać samodzielnie zmian w projekcie?",
      answer:
        "W razie potrzeby samodzielnego wprowadzania zmian w projekcie implementujemy intuicyjny panel administracyjny. Co więcej zapewniamy bezpłatne szkolenie z obsługi panelu oraz zestaw poradników.",
      isOpen: false,
    },
    {
      question: "Jestem osobą nietechniczną, co potrzebuję wiedzieć?",
      answer:
        "Każda nasza realizacja jest kompleksowa, Opiekun Projektu odpowie na wszystkie Twoje pytania oraz przeprowadzi się przez cały proces realizacji bezproblemowo.",
      isOpen: false,
    },
    {
      question: "W jaki sposób prowadzona jest współpraca?",
      answer:
        "Proces dostosowujemy do preferencji klienta. Możemy zarówno prowadzić korespondencję całkowicie mailowo, telefonicznie bądź z wykorzystaniem takich narzędzi jak Microsoft Teams oraz Zoom.",
      isOpen: false,
    },
    {
      question: "Czy zapewniacie domenę, serwer oraz certyfikat SSL?",
      answer:
        "Tak, w ramach każdego projektu zapewniamy domenę oraz szybki serwer na rok. Po roku możesz przedłużyć utrzymanie projektu u naszego Partnera - LH.pl bądź wybrać dowolnego innego dostawcę.",
      isOpen: false,
    },
    {
      question: "Czy projekt będzie dostosowany do urządzeń mobilnych?",
      answer:
        "Tak, każdy projekt posiada pełną responswyność. Dzięki temu wyświetla się poprawnie na wszystkich urządzeniach.",
      isOpen: false,
    },
    {
      question: "Pomożecie mi w marketingu?",
      answer:
        "Tak. Nie jesteśmy jednak agencją marketignową. Specjalizujemy się w realizacji projektów informatycznych. Współpracujemy jednak z firmą WIDOCZNI, która zaopiekuje się marketingiem oraz SEO Twojego projektu.",
      isOpen: false,
    },
    {
      question: "Czym jest aplikacja Let's Offer?",
      answer:
        "Jest to nasz autorski projekt, który pozwala na tworzenie ofert w ramach wcześniej przygotowanego szablonu. Wystarczy uzupełnić ofertę o wybrane parametry i wygenerować link jednym przyciskiem. Ofertę możesz przesłać w jednym linku do Klienta. Dzięki temu wszystkie oferty znajdziesz w jednym miejscu oraz wyróżnisz się na tle pozostałych konkurentów, którzy wciąż wysyłają oferty w PDF bądź w mailu. Dostęp otrzymasz dożytownio w ramach każdej realizacji.",
      isOpen: false,
    },
  ];

  const [questions, setQuestions] = useState(initialQuestions);

  const handleToggle = (index: number) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
      return updatedQuestions;
    });
  };

  return (
    <>
      <div
        id="najczesciej-zadawane-pytania"
        className="flex flex-col items-start justify-start p-6 lg:p-24"
      >
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Najczęściej zadawane pytania
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Kilka pytań oraz odpowiedzi
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        {questions.map((q, index) => (
          <div
            key={index}
            className={`lightWhite faqBox px-6 w-full rounded-xl flex flex-col mb-4 items-center z-50 justify-between ${
              q.isOpen ? "open" : ""
            }`}
          >
            <div
              className="flex flex-row items-center justify-between w-full cursor-pointer"
              onClick={() => handleToggle(index)}
            >
              <p className="text-white py-4 text-base lg:text-xl mt-1">
                {q.question}
              </p>
              <svg
                width={25}
                height={25}
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>plus-circle</title>{" "}
                    <desc>Created with Sketch Beta.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      {" "}
                      <g
                        id="Icon-Set"
                        transform="translate(-464.000000, -1087.000000)"
                        fill="#ffffff"
                      >
                        {" "}
                        <path
                          d="M480,1117 C472.268,1117 466,1110.73 466,1103 C466,1095.27 472.268,1089 480,1089 C487.732,1089 494,1095.27 494,1103 C494,1110.73 487.732,1117 480,1117 L480,1117 Z M480,1087 C471.163,1087 464,1094.16 464,1103 C464,1111.84 471.163,1119 480,1119 C488.837,1119 496,1111.84 496,1103 C496,1094.16 488.837,1087 480,1087 L480,1087 Z M486,1102 L481,1102 L481,1097 C481,1096.45 480.553,1096 480,1096 C479.447,1096 479,1096.45 479,1097 L479,1102 L474,1102 C473.447,1102 473,1102.45 473,1103 C473,1103.55 473.447,1104 474,1104 L479,1104 L479,1109 C479,1109.55 479.447,1110 480,1110 C480.553,1110 481,1109.55 481,1109 L481,1104 L486,1104 C486.553,1104 487,1103.55 487,1103 C487,1102.45 486.553,1102 486,1102 L486,1102 Z"
                          id="plus-circle"
                        >
                          {" "}
                        </path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </svg>
            </div>
            <div className="text-white w-full flex flex-col items-start justify-start mt-2">
              {q.isOpen && <p className="opacity-60 pb-4">{q.answer}</p>}
            </div>
          </div>
        ))}

        <div className="flex flex-col lg:flex-row items-center justify-center gap-6 mt-6 w-full">
          <p className="text-white text-xl">
            Nie znalazłeś odpowiedzi na pytanie?
          </p>
          <a href="/bezplatna-konsultacja">
            <button className="firstButton flex flex-row items-center justify-center gap-1">
              <span>Porozmawiajmy</span>
              <svg
                width={25}
                height={25}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                    stroke="#ffffff"
                    stroke-width="1.2"
                    stroke-linecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Faq;
