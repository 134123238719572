import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BASE_URL from "../../config";

interface SupervisorType {
  name: string;
  pdf: string;
  var1_price: string;
  var1_timing: number;
  var2_price: string;
  var2_timing: number;
  var3_price: string;
  var3_timing: number;
  var4_price: string;
  var4_timing: number;
  slug: string;
  date_added: string;
  date_expiration: string;
  supervisorName: string;
  supervisorEmail: string;
  supervisorPhone: string;
  supervisorAvatar: string;
  is_pdf: boolean;
}

function Supervisor() {
  const [offer, setOffer] = useState<SupervisorType | null>(null);
  const { offerSlug } = useParams<{ offerSlug: string }>();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/letsoffer/websiteoffer/${offerSlug}/`)
      .then((res) => setOffer(res.data))
      .catch((err) => console.log(err));
  }, [offerSlug]);

  if (!offer) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="mx-6 lg:mx-24 flex flex-col lg:flex-row pb-12 border-b border-white">
        <div className="w-full flex flex-col gap-1 items-start justify-center">
          <p className="text-3xl lg:text-5xl text-white fontRegular">
            Oferta indywidualna
          </p>
          <p className="text-2xl lg:text-3xl text-white">{offer.name}</p>
        </div>
        <div className="w-full flex flex-col gap-1 lg:items-end justify-center mt-6 lg:mt-0">
          <p className="text-lg text-white fontRegular">
            Data przygotowania oferty: <span>{offer.date_added}</span>
          </p>
          <p className="text-lg text-white fontRegular">
            Oferta ważna do: <span>{offer.date_expiration}</span>
          </p>
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-end mt-2 gap-2 shrink-0">
            {offer.is_pdf && (
              <a href={offer.pdf} rel="noreferrer" download target="_blank">
                <button className="secondButton">Pobierz wycenę w PDF</button>
              </a>
            )}
            <a href="#video">
              <button className="firstButton">Omówienie w formie video</button>
            </a>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-24 flex flex-col lg:flex-row gap-12 w-full mt-12">
        <div
          className="flex flex-col gap-1 backdrop-blur-xl lightWhite px-12 py-8 rounded-xl"
          style={{ minWidth: "35%" }}
        >
          <p className="text-3xl lg:text-5xl text-white font-semibold">
            Twój opiekun
          </p>
          <span className="mainColorBg w-12 h-1 mb-6 mt-2"></span>
          <div className="flex flex-row items-center justify-start gap-6 mb-6">
            <img
              src={offer.supervisorAvatar}
              alt="Avatar"
              className="w-24 rounded-full"
            />
            <div className="flex flex-col text-lg items-start justify-start">
              <p className="headerLink font-semibold">{offer.supervisorName}</p>
              <a href="mailto:hello@ifil.pl" className="headerLink">
                {offer.supervisorEmail}
              </a>
              <a href="tel:+48515516387" className="headerLink">
                {offer.supervisorPhone}
              </a>
            </div>
          </div>
          <a href="#kontakt">
            <button className="firstButton w-full">
              Porozmawiajmy o ofercie
            </button>
          </a>
        </div>
        <div className="flex flex-col gap-1 backdrop-blur-xl lightWhite w-full px-6 lg:px-12 py-8 rounded-xl text-lg">
          <p className="text-3xl lg:text-5xl text-white font-semibold">
            Spis treści
          </p>
          <span className="mainColorBg w-12 h-1 mb-6 mt-2"></span>

          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col gap-1 items-start justify-start">
              <a
                className="text-white headerLink animate-pulse"
                href="#wycena-indywidualna"
              >
                1. Wycena indywidualna
              </a>
              <a className="text-white headerLink" href="#proces-wspolpracy">
                2. Proces współpracy
              </a>
              <a
                className="text-white headerLink"
                href="#co-wchodzi-w-sklad-realizacji"
              >
                3. Co wchodzi w skład realizacji
              </a>
              <a className="text-white headerLink" href="#skad-wynika-cena">
                4. Skąd wynika cena
              </a>
              <a className="text-white headerLink" href="#na-co-zwrocic-uwage">
                5. Na co zwrócić uwagę
              </a>
            </div>
            <div className="flex flex-col gap-1 items-start justify-start">
              <a className="text-white headerLink" href="#korzysci-biznesowe">
                6. Korzyści biznesowe
              </a>
              <a
                className="text-white headerLink"
                href="#najczesciej-zadawane-pytania"
              >
                7. Najczęściej zadawane pytania
              </a>
              <a className="text-white headerLink" href="#o-nas">
                8. O nas
              </a>
              <a className="text-white headerLink" href="#realizacje">
                9. Realizacje
              </a>
              <a className="text-white headerLink" href="#kontakt">
                10. Kontakt
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Supervisor;
