import BackgroundSquare from "../common/squareBg";
import BasicForm from "../forms/basicForm";

function Contact() {
  return (
    <div id="kontakt">
      <section className="blackColorBg flex flex-col relative">
        <div className="absolute z-10 w-full h-full">
          <BackgroundSquare />
        </div>
        <div className="z-20 w-full h-full flex flex-col lg:flex-row lg:items-center lg:justify-between px-6 lg:px-24 pt-24 lg:pt-48 pb-12 lg:pb-36 gap-24">
          <div className="w-full flex flex-col gap-6 text-lg text-white">
            <h1 className="text-3xl lg:text-6xl text-white whiteHeading">
              Wybierzmy technologię dla{" "}
              <span className="colorHeading fontRegular">Twojego projektu</span>
            </h1>
            <p className="opacity-60">
              Produkt informatyczny taki jak strona, sklep, czy aplikacja może
              zostać zrealizowany na wiele sposobów - z wykorzystaniem różnych
              technologii. Dobór odpowiednich narzędzi i technologii wpływa
              m.in. na funkcjonalności, wydajność oraz pozycjonowanie.
            </p>
            <p className="opacity-60">
              Umów się na bezpłatną konultację, w której omówimy Twoje potrzeby
              i przedstawimy możliwe rozwiązania.
            </p>
            <div className="flex flex-col lg:flex-row lg:items-center justify-start gap-2">
              <a href="mailto:hello@ifil.pl">
                <button className="firstButton flex flex-row items-center justify-center gap-2">
                  <span>hello@ifil.pl</span>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M4 18L9 12M20 18L15 12M3 8L10.225 12.8166C10.8665 13.2443 11.1872 13.4582 11.5339 13.5412C11.8403 13.6147 12.1597 13.6147 12.4661 13.5412C12.8128 13.4582 13.1335 13.2443 13.775 12.8166L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </button>
              </a>
              <a href="tel:+48515516387">
                <button className="secondButton flex flex-row items-center justify-center gap-2">
                  <span>+48 515 516 387</span>
                  <svg
                    width={25}
                    height={25}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                        stroke="#6f00d8"
                        stroke-width="1.2"
                        stroke-linecap="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </button>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://outlook.office365.com/owa/calendar/FilipKaniaiFilGroup@ifil.pl/bookings/"
              >
                <button className="thirdButton">
                  <svg
                    width={25}
                    height={25}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M3 9H21M7 3V5M17 3V5M6 13H8M6 17H8M11 13H13M11 17H13M16 13H18M16 17H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                        stroke="#ffffff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </button>
              </a>
            </div>
          </div>
          <div className="w-full blackColorBg p-12 firstShadowLight rounded-xl fancy relative flex flex-col items-center justify-center">
            <div className="w-full lg:w-5/6 z-10">
              <h2 className="text-2xl lg:text-4xl text-white mb-6">
                Wypełnij{" "}
                <span className="colorHeading fontRegular">formularz</span>
              </h2>
              <BasicForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
