import Slider from "react-slick";

function Blog() {
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="blackColorBg flex flex-col pt-12 pb-12 lg:pb-0 lg:pt-36 z-50">
      <div className="px-6 lg:px-48">
        <h2 className="text-3xl lg:text-6xl whiteHeading text-left">
          Dzielimy się
          <br />
          <span className="colorHeading fontRegular">wiedzą</span>
        </h2>
      </div>
      <div className="relative overflow-hidden py-12">
        <div className="w-full h-full logoSliderGradient absolute z-50"></div>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
        />
        <script src="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"></script>
        <Slider {...settings}>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/Marketing/cross-sell%20(1).png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Cross-selling w sklepie on-line</p>
              </div>
            </div>
          </div>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/CMS/google-web-core-vitals.png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Web Core Vitalss</p>
              </div>
            </div>
          </div>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/Marketing/newsletter-n.png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Newsletter</p>
              </div>
            </div>
          </div>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/Bezpiecze%C5%84stwo/google-recaptcha.png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Google reCAPTCHA</p>
              </div>
            </div>
          </div>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/Bezpiecze%C5%84stwo/ssl.png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Certyfikat SSL</p>
              </div>
            </div>
          </div>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/Bezpiecze%C5%84stwo/podstawowe-cyberataki.png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Podstawowe cyberataki</p>
              </div>
            </div>
          </div>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/Serwery/ioncube.png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Narzędzie ionCube</p>
              </div>
            </div>
          </div>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/Serwery/rodzaje-hostingow.png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Rodzaje hostingów</p>
              </div>
            </div>
          </div>
          <div className="px-2 flex-col items-center justify-center">
            <div className="flex flex-col rounded-xl overflow-hidden">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Blog/Featured/Bezpiecze%C5%84stwo/propagacja-domen.png"
                alt=""
              />
              <div className="mainColorBg flex flex-col items-center justify-center p-4 text-center text-white">
                <p>Propagacja domeny</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className="flex flex-row items-center justify-center gap-6">
        <p className="text-white text-xl">Dowiedz się więcej</p>
        <a href="/pomoc/baza-wiedzy">
          <button className="firstButton">Zobacz</button>
        </a>
      </div>
    </section>
  );
}
export default Blog;
