import { motion, useScroll, useTransform } from "framer-motion";

import BackgroundSquare from "../common/squareBg";

function Hero() {
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 0.2], [0.95, 1.1]);

  return (
    <section className="blackColorBg lg:min-h-screen flex flex-col items-center justify-end gap-12 overflow-hidden relative">
      <div className="w-full h-full object-cover absolute inset-0">
        {" "}
        <BackgroundSquare />
      </div>
      <div className="flex flex-col items-center justify-center pt-12 lg:pt-36 pb-6 z-20">
        <h1 className="mainColor fontLight text-center text-sm lg:text-xl">
          iFil Group - Projektowanie interfejsów graficznych
        </h1>
        <h2 className="text-3xl lg:text-7xl text-center mb-6">
          <span className="whiteHeading fontRegular">
            Tworzymy wrażenia, które
          </span>{" "}
          <br />
          <span className="whiteHeading fontRegular">zwiększają</span>{" "}
          <span className="colorHeading fontRegular">sprzedaż</span>
        </h2>

        <p className="text-white text-xl lg:text-2xl opacity-60 mb-12 w-full lg:w-2/3 text-center">
          Z nami każdy pixel ma znaczenie - projektujemy interfejsy z dbałością
          o detale.
        </p>
        <a href="/bezplatna-konsultacja">
          <button className="secondButton flex flex-row items-center justify-center gap-1">
            <span>Porozmawiajmy o Twoich potrzebach</span>
            <svg
              width={25}
              height={25}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                  stroke="#6f00d8"
                  stroke-width="1.2"
                  stroke-linecap="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
        </a>
      </div>
      <motion.img
        src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/interfaces-ui.webp"
        alt=""
        className="rounded-t-3xl w-3/4 firstShadow z-10 -mb-6"
        style={{ scale }}
      />
    </section>
  );
}
export default Hero;
