import Slider from "react-slick";

function Industry() {
  var settings = {
    slidesToShow: 2,
    centerMode: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="blackColorBg flex flex-col pt-12 pb-12 lg:pb-0 lg:pt-24 z-50">
      <div className="px-0 lg:px-48 flex flex-col items-center justify-center">
        <p className="text-white px-6 rounded-xl py-1 mixedGradients mb-6">
          Z jakimi branżami pracujemy
        </p>
        <h2 className="text-3xl lg:text-6xl text-center">
          <span className="whiteHeading fontRegular">
            Wdrażamy nowoczesne rozwiązania
          </span>
          <br />
          <span className="whiteHeading fontRegular">dla</span>{" "}
          <span className="colorHeading fontRegular">Twojego biznesu</span>
        </h2>
      </div>
      <div className="relative overflow-hidden py-12 px-6 lg:px-0">
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
        />
        <script src="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"></script>
        <Slider {...settings}>
          <div className="px-1 lg:px-2">
            <div className="rounded-xl overflow-hidden relative industryBox">
              <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
              <div className="absolute w-full h-full z-30 p-2 lg:p-12 flex flex-col items-start justify-end">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-1.png"
                  alt="Ikona"
                  className="w-12 lg:w-24 mb-2 hidden lg:flex lg:absolute bottom-6 left-6 lg:top-12 lg:right-12"
                />
                <h4 className="text-white text-lg lg:text-2xl">E-commerce</h4>
                <p className="text-white w-full mt-2 opacity-60 text-sm lg:text-base">
                  Oferujemy kompleksowe rozwiązania e-commerce, które nie tylko
                  przyciągają klientów, ale także zwiększają sprzedaż i
                  lojalność.
                </p>
              </div>
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-ecommerce.webp"
                alt=""
              />
            </div>
          </div>
          <div className="px-1 lg:px-2">
            <div className="rounded-xl overflow-hidden relative industryBox">
              <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
              <div className="absolute w-full h-full z-30 p-2 lg:p-12 flex flex-col items-start justify-end">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-2.png"
                  alt="Ikona"
                  className="w-12 lg:w-24 mb-2 hidden lg:flex lg:absolute bottom-6 left-6 lg:top-12 lg:right-12"
                />
                <h4 className="text-white text-lg lg:text-2xl">
                  Firmy usługowe
                </h4>
                <p className="text-white w-full mt-2 opacity-60 text-sm lg:text-base">
                  Dostarczamy innowacyjne narzędzia dla sektora usługowego,
                  ułatwiając efektywną komunikację i zwiększając wydajność w
                  relacjach biznesowych.
                </p>
              </div>
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-uslugi.webp"
                alt=""
              />
            </div>
          </div>
          <div className="px-1 lg:px-2">
            <div className="rounded-xl overflow-hidden relative industryBox">
              <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
              <div className="absolute w-full h-full z-30 p-2 lg:p-12 flex flex-col items-start justify-end">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-3.png"
                  alt="Ikona"
                  className="w-12 lg:w-24 mb-2 hidden lg:flex lg:absolute bottom-6 left-6 lg:top-12 lg:right-12"
                />
                <h4 className="text-white text-lg lg:text-2xl">
                  Marki osobiste
                </h4>
                <p className="text-white w-full mt-2 opacity-60 text-sm lg:text-base">
                  Tworzymy indywidualne projekty, aby podkreślić unikalność
                  Twojej marki i budować trwałe relacje z widownią.
                </p>
              </div>
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-marki-osobiste.webp"
                alt=""
              />
            </div>
          </div>
          <div className="px-1 lg:px-2">
            <div className="rounded-xl overflow-hidden relative industryBox">
              <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
              <div className="absolute w-full h-full z-30 p-2 lg:p-12 flex flex-col items-start justify-end">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-4.png"
                  alt="Ikona"
                  className="w-12 lg:w-24 mb-2 hidden lg:flex lg:absolute bottom-6 left-6 lg:top-12 lg:right-12"
                />
                <h4 className="text-white text-lg lg:text-2xl">
                  Produkcja i przemysł
                </h4>
                <p className="text-white w-full mt-2 opacity-60 text-sm lg:text-base">
                  Implementujem rozwiązania, które zoptymalizują procesy w
                  firmie i podniosą wydajność w sektorze przemysłowym.
                </p>
              </div>
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-przemysl-produkcja.webp"
                alt=""
              />
            </div>
          </div>
          <div className="px-1 lg:px-2">
            <div className="rounded-xl overflow-hidden relative industryBox">
              <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
              <div className="absolute w-full h-full z-30 p-2 lg:p-12 flex flex-col items-start justify-end">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-5.png"
                  alt="Ikona"
                  className="w-12 lg:w-24 mb-2 hidden lg:flex lg:absolute bottom-6 left-6 lg:top-12 lg:right-12"
                />
                <h4 className="text-white text-lg lg:text-2xl">Finanse</h4>
                <p className="text-white w-full mt-2 opacity-60 text-sm lg:text-base">
                  Dostarczamy nowoczesne aplikacje internetowe, aby zwiększyć
                  efektywność operacyjną w sektorze finansowym.
                </p>
              </div>
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-finanse.webp"
                alt=""
              />
            </div>
          </div>
          <div className="px-1 lg:px-2">
            <div className="rounded-xl overflow-hidden relative industryBox">
              <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
              <div className="absolute w-full h-full z-30 p-2 lg:p-12 flex flex-col items-start justify-end">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-6.png"
                  alt="Ikona"
                  className="w-12 lg:w-24 mb-2 hidden lg:flex lg:absolute bottom-6 left-6 lg:top-12 lg:right-12"
                />
                <h4 className="text-white text-lg lg:text-2xl">
                  Medycyna i fizjoterapia
                </h4>
                <p className="text-white w-full mt-2 opacity-60 text-sm lg:text-base">
                  Oferujemy projekt oraz implementację stron internetowych z
                  oprogramowaniem do rezerwacji wizyt.
                </p>
              </div>
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-medycyna.webp"
                alt=""
              />
            </div>
          </div>
          <div className="px-1 lg:px-2">
            <div className="rounded-xl overflow-hidden relative industryBox">
              <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
              <div className="absolute w-full h-full z-30 p-2 lg:p-12 flex flex-col items-start justify-end">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-7.png"
                  alt="Ikona"
                  className="w-12 lg:w-24 mb-2 hidden lg:flex lg:absolute bottom-6 left-6 lg:top-12 lg:right-12"
                />
                <h4 className="text-white text-lg lg:text-2xl">Prawo</h4>
                <p className="text-white w-full mt-2 opacity-60 text-sm lg:text-base">
                  Wprowadzamy nowoczesne rozwiązania, do prowadzenia wirtualnych
                  kancelarii prawnych.
                </p>
              </div>
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-prawo.webp"
                alt=""
              />
            </div>
          </div>
          <div className="px-1 lg:px-2">
            <div className="rounded-xl overflow-hidden relative industryBox">
              <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
              <div className="absolute w-full h-full z-30 p-2 lg:p-12 flex flex-col items-start justify-end">
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-8.png"
                  alt="Ikona"
                  className="w-12 lg:w-24 mb-2 hidden lg:flex lg:absolute bottom-6 left-6 lg:top-12 lg:right-12"
                />
                <h4 className="text-white text-lg lg:text-2xl">
                  Agroturystyka i hotelarstwo
                </h4>
                <p className="text-white w-full mt-2 opacity-60 text-sm lg:text-base">
                  W atrakcyjny sposbów prezentujemy ofertę hoteli, apartamentów
                  oraz region, w którym działa biznes.
                </p>
              </div>
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-agroturystyka.webp"
                alt=""
              />
            </div>
          </div>
        </Slider>
        <div className="w-full flex flex-col items-center justify-center pt-20">
          <a href="/bezplatna-konsultacja">
            <button className="secondButton flex flex-row items-center justify-center gap-1">
              <span>Porozmawiajmy o szansie dla Twojej marki</span>
              <svg
                width={25}
                height={25}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                    stroke="#6f00d8"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}
export default Industry;
