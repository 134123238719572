import { useEffect, useRef } from "react";
import BackgroundSquare from "../common/squareBg";

export default function Forest() {
  const svgRef1 = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
          } else {
            entry.target.classList.remove("active");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const currentSvg = svgRef1.current;

    if (currentSvg) {
      observer.observe(currentSvg);
    }

    return () => {
      if (currentSvg) {
        observer.unobserve(currentSvg);
      }
    };
  }, []);

  return (
    <section className="grayLight flex flex-col lg:flex-row items-center justify-between gap-12 relative">
      <div className="absolute w-full h-full">
        <BackgroundSquare />
      </div>
      <div className="p-6 lg:p-12 flex flex-col lg:flex-row-reverse items-center justify-between">
        <div className="w-full lg:w-full z-30 flex flex-col items-start justify-center">
          <p className="text-white px-6 rounded-xl py-1 mixedGradients">
            Zrównoważony rozwój
          </p>
          <h2 className="text-3xl lg:text-6xl text-left mb-6">
            <span className="whiteHeading fontRegular">Wspólnie zadbajmy</span>
            <br />
            <span className="whiteHeading fontRegular">o nasze</span>{" "}
            <span className="colorHeading fontRegular">środowisko</span>
          </h2>

          <p className="text-white text-lg lg:text-2xl opacity-60 mb-6 text-left w-full lg:w-3/4">
            W ramach każdego zrealizowanego projektu sadzimy 10 drzewek! Po
            zakończonym projekcie otrzymasz zaświadczenie od naszego Partnera.
          </p>
          <a href="/bezplatna-konsultacja">
            <button className="firstButton flex flex-row items-center justify-center gap-1">
              <span>Wspieraj z nami środowisko</span>
              <svg
                width={25}
                height={25}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                    stroke="#ffffff"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </a>
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-6 pt-12 lg:pt-0 forestSvg z-50 relative">
          <img
            className="w-2/3"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/sadzimy-drzewa-ifil.webp"
            alt=""
          />
          <svg
            ref={svgRef1}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            className="absolute bottom-6 lg:bottom-12 right-6 lg:right-12 w-[100px] lg:w-[200px] lg:h-[200px]"
          >
            <path
              className="st0 svg-elem-1"
              fill="none"
              stroke="#6f00d8"
              strokeWidth="2"
              d="M81.7,52.7c1.8-1,3.4-2.2,5-3.6l-8.4-12c1.8-0.9,3.5-2.1,5.1-3.5L66.7,10L54.8,26.9L50,20l-4.8,6.9L33.3,10
                L16.7,33.8c1.5,1.4,3.2,2.5,5.1,3.5l-8.4,12c1.5,1.4,3.2,2.6,5,3.6L10,64.6c4.7,4.3,10.6,7,16.7,8.1v10.6h6.7v-4.1
                c4.1,2.2,8.7,3.5,13.3,3.9V90h6.7v-6.9c4.6-0.4,9.2-1.7,13.3-3.9v4.1h6.7V72.7c6.1-1.1,11.9-3.8,16.7-8.1L81.7,52.7z M63.9,39.8
                c2.6,0.3,5.2,0.2,7.8-0.3l5.6,8c-3.2,1.6-6.9,2.5-10.7,2.5c-1.1,0-2.2-0.1-3.3-0.2l-1.8-2.6c1.8-0.9,3.5-2.1,5.1-3.5L63.9,39.8z
                M66.7,21.6l7.2,10.3c-2.2,0.9-4.7,1.4-7.2,1.4c-2.6,0-5-0.5-7.2-1.4L66.7,21.6z M60.7,57.5C57.5,59.1,53.8,60,50,60
                c-3.8,0-7.5-0.9-10.7-2.5l5.6-8c3.3,0.7,6.8,0.7,10.1,0L60.7,57.5z M50,31.6l7.2,10.3c-2.2,0.9-4.7,1.4-7.2,1.4
                c-2.6,0-5-0.5-7.2-1.4L50,31.6z M33.3,21.6l7.2,10.3c-2.2,0.9-4.7,1.4-7.2,1.4c-2.6,0-5-0.5-7.2-1.4L33.3,21.6z M28.3,39.5
                c2.6,0.5,5.2,0.6,7.8,0.3l-2.8,3.9c1.5,1.4,3.2,2.5,5.1,3.5l-1.8,2.6c-1.1,0.1-2.2,0.2-3.3,0.2c-3.8,0-7.5-0.9-10.7-2.5L28.3,39.5z
                M32.3,66.6c-4.7-0.2-9.2-1.4-13.1-3.5l5.4-7.7c2.4,0.7,4.8,1.1,7.2,1.2L30,59.2c1.5,1.4,3.2,2.6,5,3.6L32.3,66.6z M50,76.7
                c-5.1,0-9.9-1.3-14.1-3.6l5.4-7.7c5.7,1.7,11.8,1.7,17.5,0l5.4,7.7C59.9,75.4,55.1,76.7,50,76.7z M67.7,66.6L65,62.7
                c1.8-1,3.4-2.2,5-3.6l-1.8-2.6c2.4-0.1,4.9-0.5,7.2-1.2l5.4,7.7C76.9,65.2,72.4,66.4,67.7,66.6z"
            ></path>
          </svg>
        </div>
      </div>
    </section>
  );
}
