import { useEffect, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import BackgroundSquare from "../common/squareBg";
import { FlipWords } from "../additional/flipWords";
import { FaPenToSquare } from "react-icons/fa6";

function Hero() {
  const words = ["aplikacje", "strony", "sklepy", "interfejsy", "CRM"];

  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 0.02], [0.84, 1.2]);
  const rotate = useTransform(scrollYProgress, [0, 0.02], [-2, 0]);
  const rotateX = useTransform(scrollYProgress, [0, 0.02], [10, 0]);
  const rotateY = useTransform(scrollYProgress, [0, 0.02], [12, 0]);
  const translateY = useTransform(scrollYProgress, [0, 0.02], [0, 10]);
  const translateX = useTransform(scrollYProgress, [0, 0.02], [50, 0]);
  const perspective = 1200;

  useEffect(() => {
    const video = document.querySelector("video");
    if (video) {
      video.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
  }, []);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.defaultMuted = true;
    }
  }, []);

  return (
    <section className="blackColorBg lg:min-h-[120vh] flex flex-col items-center justify-between relative overflow-hidden">
      <div className="btt-gradient-black w-full absolute bottom-0 lg:h-[75vh] z-50"></div>
      <div className="w-full h-full object-cover absolute inset-0">
        <BackgroundSquare />
      </div>
      <div className="flex flex-col items-center justify-center z-20 pt-24 lg:pt-48 px-6">
        <img
          className="flex lg:hidden w-1/2"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/logo-white.png"
          alt=""
        />
        <h1 className="mainColor fontLight text-[0px] text-center">
          iFil Group - Tworzenie stron internetowych, sklepów i aplikacji
        </h1>
        <h2 className="mainColor text-sm lg:text-2xl text-center mt-2 lg:mt-0">
          Przenosimy firmy na wyższy poziom w sieci
        </h2>
        <h2 className="text-3xl lg:text-8xl whiteHeading text-center mb-12">
          Tworzymy <FlipWords words={words} />
          ,<br />
          które rozwiną Twój biznes
        </h2>
        <a
          className="mb-12 flex flex-col items-center justify-center gap-2"
          href="/wycena"
        >
          <button className="group firstButton flex flex-row items-center justify-center gap-2 mb-2 text-lg">
            <FaPenToSquare className="text-white" />
            <span className="hidden lg:block">
              Skorzystaj z bezpłatnej wyceny
            </span>
            <span className="block lg:hidden">Bezpłatna wycena</span>
          </button>
          <p className="text-white opacity-60 text-xl">
            To zajmuje tylko 60 sekund!
          </p>
        </a>
      </div>
      <motion.div
        className="hidden lg:flex flex-col items-center justify-center w-full pt-6"
        style={{
          perspective: `${perspective}px`,
        }}
      >
        <motion.video
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/herovideo-speedup.mp4"
          className="rounded-t-3xl w-3/4 firstShadow z-10"
          muted={true}
          loop
          autoPlay={true}
          playsInline
          style={{
            scale,
            rotate,
            rotateX,
            rotateY,
            translateY,
            translateX,
          }}
          ref={videoRef}
        />
      </motion.div>
      <div
        className="flex lg:hidden flex-col items-center justify-center w-full translate-y-2"
        style={{
          perspective: `${perspective}px`,
        }}
      >
        <video
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/herovideo-speedup.mp4"
          className="rounded-t-3xl w-5/6 firstShadow z-10"
          muted
          loop
          autoPlay
          ref={videoRef}
          playsInline
        />
      </div>
    </section>
  );
}

export default Hero;
