function HowWeWork() {
  return (
    <section
      id="jak-dzialamy"
      className="blackColorBg px-6 lg:px-36 flex flex-col lg:flex-row items-center justify-center lg:pt-24 pb-12 lg:pb-0 gap-12 relative letsofferGradient"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full text-white">
        <div className="relative flex flex-col pb-24 items-center justify-start">
          <div className="lg:sticky lg:top-36  flex flex-col items-center justify-center relative">
            <img
              className="w-full"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/porownajsolary-ui.webp"
              alt="Mockup"
            />
            <div className="absolute bottom-0 left-0 px-2 w-40 h-40 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
              <p className="text-center">
                Nasza autorska aplikacja z branży OZE
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start">
          <p className="text-white px-6 rounded-xl py-1 mixedGradients mb-6">
            Jak pracujemy
          </p>
          <h2 className="text-3xl lg:text-6xl text-left mb-12">
            <span className="whiteHeading fontRegular">
              Jak przebiega proces
            </span>{" "}
            <span className="colorHeading fontRegular">współpracy</span>?
          </h2>
          <div className="w-full flex flex-col gap-12 lg:gap-36">
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-4">
              <p className="whiteHeading text-5xl font-semibold lg:text-6xl">
                01
              </p>
              <p className="whiteHeading text-2xl lg:text-5xl">
                Poznanie potrzeb
              </p>
              <p className="text-white w-full mt-2 text-xl">
                <span className="opacity-60">Przeprowadzamy</span>{" "}
                <span>spotkanie on-line</span>{" "}
                <span className="opacity-60">bądź prosimy Cię o</span>{" "}
                <span>uzupełnienie naszego formularza</span>.{" "}
                <span className="opacity-60">Dzięki temu poznamy</span>{" "}
                <span>Twoje potrzeby, problemy</span>{" "}
                <span className="opacity-60">
                  jakimi może zmagać się Twoja marka oraz
                </span>{" "}
                <span>założenia</span>{" "}
                <span className="opacity-60">dotyczące nowego projektu.</span>
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-4">
              <p className="whiteHeading text-5xl font-semibold lg:text-6xl">
                02
              </p>
              <p className="whiteHeading text-2xl lg:text-5xl">
                Research i koncepcja
              </p>
              <p className="text-white w-full mt-2 text-xl">
                <span className="opacity-60">
                  Na bazie zebranych informacji przeprowadzamy research rynku
                  Twojej branży oraz opracowujemy koncepcję projektu, tak aby
                  przyniósł
                </span>{" "}
                <span>realną korzyść</span>
                <span className="opacity-60">
                  {" "}
                  dla Twojej działalności. Na tym etapie skupiamy się na{" "}
                </span>
                User Experience{" "}
                <span className="opacity-60">, aby projekt był </span>
                <span className="">użyteczny oraz intuicyjny.</span>
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-4">
              <p className="whiteHeading text-5xl font-semibold lg:text-6xl">
                03
              </p>
              <p className="whiteHeading text-2xl lg:text-5xl">
                Projekt interfejsu
              </p>
              <p className="text-white w-full mt-2 text-xl">
                <span className="opacity-60">
                  Projektujemy layout graficzny Twojego nowego projektu. Dbamy o
                </span>{" "}
                <span className="">spójność z identyfikacją wizualną</span>{" "}
                <span className="opacity-60">
                  Twojej marki. Kładziemy duży nacisk nie tylko na
                </span>{" "}
                <span className="">estetykę oraz atrakcyjność</span>{" "}
                <span className="opacity-60">
                  Twojego projektu, ale również na
                </span>{" "}
                <span className="">aspekt biznesowy.</span>
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-4">
              <p className="whiteHeading text-5xl font-semibold lg:text-6xl">
                04
              </p>
              <p className="whiteHeading text-2xl lg:text-5xl">
                Wdrożenie techniczne
              </p>
              <p className="text-white w-full mt-2 text-xl">
                <span className="opacity-60">
                  Interfejs graficzny przekładany jest na{" "}
                </span>{" "}
                <span>kod programistyczny</span>
                <span className="opacity-60">
                  . Każdy nasz projekt realizujemy{" "}
                </span>{" "}
                <span>od zera</span>{" "}
                <span className="opacity-60">Skupiamy się na </span>
                <span>czystości kodu</span>
                <span className="opacity-60">
                  {" "}
                  dzięki temu Twój projekt będzie
                </span>
                <span> szybki oraz wydajny</span>
                <span className="opacity-60">.</span>
                <span className="opacity-60"> Projekt integrujemy z </span>
                <span>panelem administracyjnym</span>
                <span className="opacity-60">
                  , aby Klient mógł edytować treść
                </span>
                <span> bez pomocy programisty</span>
                <span className="opacity-60">.</span>
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 lg:gap-4">
              <p className="whiteHeading text-5xl font-semibold lg:text-6xl">
                05
              </p>
              <p className="whiteHeading text-2xl lg:text-5xl">
                Wsparcie i rozwój
              </p>
              <p className="text-white w-full mt-2 text-xl">
                <span className="opacity-60">Zapewniamy pełen zestaw</span>
                <span> instruktaży oraz poradników</span>
                <span className="opacity-60">. Dysponujemy</span>
                <span> działem wsparcia</span>
                <span className="opacity-60">
                  , który wspiera naszych Klientów
                </span>
                <span> cały tydzień</span>
                <span className="opacity-60">.</span>
                <span className="opacity-60"> Dbamy o </span>
                <span>rozwój naszych Klientów</span>
                <span className="opacity-60">
                  {" "}
                  regularnie informując ich o{" "}
                </span>
                <span>nowych możliwościach</span>
                <span className="opacity-60">
                  , które pojawiają się w technologii.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default HowWeWork;
