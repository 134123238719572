import { useState } from "react";
import BackgroundSquare from "../common/squareBg";
import PartnerForm from "../forms/partnerForm";
function Content() {
  const initialQuestions = [
    {
      question: "Co to jest program partnerski?",
      answer:
        "Program Partnerski iFil Group jest programem afiliacyjnym, dzięki któremu możesz uzyskać prowizję za realizowane przez nas usługi. Za każdego Klienta, który skorzysta z naszych usług z Twojego polecenia otrzymasz środki, które możesz wypłacić na swoje konto bankowe.",
      isOpen: false,
    },
    {
      question: "Jak dołączyć do programu partnerskiego?",
      answer:
        "Wystarczy, że zarejestrujesz się w panelu iFil. Po pomyślnej weryfikacji otrzymasz login polecającego, który możesz przekazywać potencjalnym Klientom.",
      isOpen: false,
    },
    {
      question: "Jakie usługi mogę polecać?",
      answer:
        "W skład podstawowej oferty iFil wchodzą strony internetowe, sklepy WWW, systemy rezerwacji, interfejsy graficzne, aplikacje internetowe oraz aplikacje iOS. Dodatkowo dzięki współpracy z firmami LH.pl oraz WIDOCZNI pośredniczymy w usługach zaplecza serwerowego oraz pozycjonowania i kampanii reklamowych.",
      isOpen: false,
    },
    {
      question: "Jak promować usługi w programie partnerskim?",
      answer:
        "Materiały marketingowe oraz login polecającego możesz umieścić w swoich opisach, na swojej stronie, social mediach, na blogu. Możesz również swój login przekazywać bezpośrednio do firm, z którymi współpracujesz, znajomych oraz rodziny. Uwaga! Pamiętaj, że Twoje działania nie mogą naruszać naszego Regulaminu oraz Rozporządzenia o Ochronie Danych Osobowych.",
      isOpen: false,
    },
    {
      question: "Jak wypłacić zgromadzone środki?",
      answer:
        "Po opłaceniu faktury przez Poleconego Klienta otrzymasz prowizję, którą możesz wypłacić na swoje konto bankowe. Wystarczy, że prześlesz dyspozycję na adres hello@ifil.pl. Wypłaty realizowane są w przeciągu 5 dni roboczych.",
      isOpen: false,
    },
  ];

  const [questions, setQuestions] = useState(initialQuestions);

  const handleToggle = (index: number) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
      return updatedQuestions;
    });
  };

  return (
    <section className="blackColorBg flex flex-col items-center justify-start gap-12 relative">
      <div className="absolute z-10 w-full h-full">
        <div className="w-full lg:h-screen object-cover sticky top- inset-0">
          {" "}
          <BackgroundSquare />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center pt-12 lg:pt-36 z-20">
        <h1 className="mainColor fontLight text-center text-sm lg:text-xl">
          iFil Group - Program partnerski
        </h1>
        <h2 className="text-3xl lg:text-7xl whiteHeading text-center">
          Polecaj nasze usługi <br />i zyskaj dodatkowy{" "}
          <span className="colorHeading fontRegular">dochód</span>
        </h2>
        <p className="text-white text-xl lg:text-2xl opacity-60 mb-12 w-full lg:w-2/3 text-center">
          Zarejestruj się w naszym programie partnerskim i zacznij zarabiać
          wysokie prowizje, bez limitu wypłat!
        </p>
        <a href="/panel/rejestracja">
          <button className="secondButton flex flex-row items-center justify-center gap-1">
            <span>Zarejestruj się</span>
            <svg
              width={25}
              height={25}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6 16.9998C6 17.3511 6 17.5268 6.01567 17.6795C6.14575 18.9473 7.0626 19.9945 8.30206 20.291C8.45134 20.3267 8.6255 20.3499 8.97368 20.3963L15.5656 21.2753C17.442 21.5254 18.3803 21.6505 19.1084 21.361C19.7478 21.1068 20.2803 20.6406 20.6168 20.0405C21 19.3569 21 18.4104 21 16.5174V7.48232C21 5.58928 21 4.64275 20.6168 3.95923C20.2803 3.35911 19.7478 2.89288 19.1084 2.63868C18.3803 2.34914 17.442 2.47423 15.5656 2.72442L8.97368 3.60335C8.62546 3.64978 8.45135 3.67299 8.30206 3.7087C7.0626 4.0052 6.14575 5.05241 6.01567 6.32018C6 6.47288 6 6.64854 6 6.99984M12 7.99984L16 11.9998M16 11.9998L12 15.9998M16 11.9998H3"
                  stroke="#6f00d8"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
        </a>
      </div>
      <div className="w-full flex flex-col items-center justify-center p-6 lg:p-24 gap-12 z-20">
        <h2 className="text-3xl lg:text-6xl text-white text-center">
          Jak polecać nasze{" "}
          <span className="mainColor fontRegular">usługi</span>
        </h2>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-12 w-full">
          <div className="p-6 lg:p-12 rounded-xl lightWhite flex flex-col items-start justify-start gap-2 backdrop-blur-2xl w-full relative">
            <h3 className="text-white text-2xl">Login polecającego</h3>
            <p className="text-white text-lg">
              Po dokonaniu rejestracji w panelu Partnera otrzymasz indywidualny
              login, przekaż go potencjalnemu Klientowi.
            </p>
            <a className="headerLinkPurple" href="/panel/rejestracja">
              Zarejestruj się
            </a>
            <img
              className="absolute bottom-0 right-0 w-1/5 opacity-10"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/uslugi-aplikacje-internetowe.png"
              alt=""
            />
          </div>
          <div className="p-12 rounded-xl lightWhite flex flex-col items-start justify-start gap-2 backdrop-blur-2xl w-full relative">
            <h3 className="text-white text-2xl">Kontakt bezpośredni</h3>
            <p className="text-white text-lg">
              Napisz do nas maila z danymi kontaktowymi potencjalnego Klienta
              oraz informacją na temat potrzebnej usługi.
            </p>
            <a className="headerLinkPurple" href="#polecKlienta">
              Poleć Klienta bezpośrednio
            </a>
            <img
              className="absolute bottom-0 right-0 w-1/5 opacity-10"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/uslugi-interfejsy-graficzne.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        id="faq"
        className="w-full pb-12 lg:pb-36 px-6 lg:px-48 flex flex-col items-center justify-center overflow-hidden relative z-20"
      >
        <h2 className="text-3xl lg:text-6xl whiteHeading text-center mb-12">
          Najczęściej zadawane{" "}
          <span className="colorHeading fontRegular">pytania</span>
        </h2>

        {questions.map((q, index) => (
          <div
            key={index}
            className={`lightWhite faqBox px-6 w-full lg:w-2/3 rounded-xl flex flex-col mb-4 items-center z-50 justify-between ${
              q.isOpen ? "open" : ""
            }`}
          >
            <div
              className="flex flex-row items-center justify-between w-full cursor-pointer"
              onClick={() => handleToggle(index)}
            >
              <p className="text-white py-4 text-xl mt-1">{q.question}</p>
              <svg
                width={25}
                height={25}
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>plus-circle</title>{" "}
                    <desc>Created with Sketch Beta.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      {" "}
                      <g
                        id="Icon-Set"
                        transform="translate(-464.000000, -1087.000000)"
                        fill="#ffffff"
                      >
                        {" "}
                        <path
                          d="M480,1117 C472.268,1117 466,1110.73 466,1103 C466,1095.27 472.268,1089 480,1089 C487.732,1089 494,1095.27 494,1103 C494,1110.73 487.732,1117 480,1117 L480,1117 Z M480,1087 C471.163,1087 464,1094.16 464,1103 C464,1111.84 471.163,1119 480,1119 C488.837,1119 496,1111.84 496,1103 C496,1094.16 488.837,1087 480,1087 L480,1087 Z M486,1102 L481,1102 L481,1097 C481,1096.45 480.553,1096 480,1096 C479.447,1096 479,1096.45 479,1097 L479,1102 L474,1102 C473.447,1102 473,1102.45 473,1103 C473,1103.55 473.447,1104 474,1104 L479,1104 L479,1109 C479,1109.55 479.447,1110 480,1110 C480.553,1110 481,1109.55 481,1109 L481,1104 L486,1104 C486.553,1104 487,1103.55 487,1103 C487,1102.45 486.553,1102 486,1102 L486,1102 Z"
                          id="plus-circle"
                        >
                          {" "}
                        </path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </svg>
            </div>
            <div className="text-white w-full flex flex-col items-start justify-start mt-2">
              {q.isOpen && <p className="opacity-60 pb-4">{q.answer}</p>}
            </div>
          </div>
        ))}

        <div className="flex flex-col lg:flex-row items-center justify-center gap-6 mt-6 z-20">
          <p className="text-white text-xl">
            Nie znalazłeś odpowiedzi na pytanie?
          </p>
          <a href="/bezplatna-konsultacja">
            <button className="firstButton flex flex-row items-center justify-center gap-1">
              <span>Porozmawiajmy</span>
              <svg
                width={25}
                height={25}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                    stroke="#ffffff"
                    stroke-width="1.2"
                    stroke-linecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </a>
        </div>
      </div>
      <div
        id="polecKlienta"
        className="w-full z-20 flex flex-col items-center justify-center px-6 lg:px-0 pb-12 gap-12"
      >
        <h2 className="text-3xl lg:text-6xl text-white text-center">
          Poleć nowego <span className="mainColor fontRegular">Klienta</span>
        </h2>
        <div className="w-full lg:w-1/2 backdrop-blur-xl p-6 lg:p-12 rounded-xl lightWhite">
          <PartnerForm />
        </div>
      </div>
    </section>
  );
}
export default Content;
