import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import BASE_URL from "../../config";

interface PricingWebsiteType {
  name: string;
  pdf: string;
  var1_price: string;
  var1_timing: number;
  var2_price: string;
  var2_timing: number;
  var3_price: string;
  var3_timing: number;
  var4_price: string;
  var4_timing: number;
  slug: string;
  date_added: string;
  date_expiration: string;
  supervisorName: string;
  supervisorEmail: string;
  supervisorPhone: string;
  supervisorAvatar: string;
  is_pdf: boolean;
}

function PricingWebsite() {
  const [offer, setOffer] = useState<PricingWebsiteType | null>(null);
  const { offerSlug } = useParams<{ offerSlug: string }>();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/letsoffer/websiteoffer/${offerSlug}/`)
      .then((res) => setOffer(res.data))
      .catch((err) => console.log(err));
  }, [offerSlug]);

  if (!offer) {
    return <div>Loading...</div>;
  }

  return (
    <div id="wycena-indywidualna" className="flex flex-col z-50 w-full gap-2">
      <div
        id="dedykowana-oferta"
        className="px-6 lg:px-24 flex flex-col mt-12 lg:mt-24 items-start justify-start"
      >
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Wycena indywidualna
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-6 mt-4">
          Dedykowane rozwiązania
        </p>
        <span className="mainColorBg w-24 h-1 mb-12"></span>
        <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-6">
          <div className="flex flex-col">
            {" "}
            <div className="lightWhite backdrop-blur-xl overflow-hidden flex flex-col items-center justify-start rounded-xl gap-1">
              <div className="px-2 py-6 flex flex-col w-full items-center justify-start">
                {" "}
                <p className="text-white uppercase mainColor">Wariant nr I</p>
                <p className="text-white text-5xl fontRegular">
                  {offer.var1_price} zł
                </p>
                <p className="text-white text-sm">netto</p>
                <span
                  className="w-3/4 bg-white my-4"
                  style={{ height: "1px" }}
                ></span>
                <p className="text-white text-base text-center mainColor">
                  WYSIWYG + Gotowy CMS
                </p>
              </div>
              <div className="bg-white w-full p-2 text-center">
                <p>Czas realizacji: {offer.var1_timing} dni</p>
              </div>
            </div>
            <div className="flex flex-col items-center mt-6">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>Panel administracyjny</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>Łatwe integracje</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Wyższe bezpieczeństwo</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Rozszerzone SEO</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Wydajniejsza i szybsza strona</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col">
            {" "}
            <div className="lightWhite backdrop-blur-xl overflow-hidden flex flex-col items-center justify-start rounded-xl gap-1">
              <div className="px-2 py-6 flex flex-col w-full items-center justify-start">
                {" "}
                <p className="text-white uppercase mainColor">Wariant nr II</p>
                <p className="text-white text-5xl fontRegular">
                  {offer.var2_price} zł
                </p>
                <p className="text-white text-sm">netto</p>
                <span
                  className="w-3/4 bg-white my-4"
                  style={{ height: "1px" }}
                ></span>
                <p className="text-white text-base text-center mainColor">
                  Indywidualny projekt bez CMS
                </p>
              </div>
              <div className="bg-white w-full p-2 text-center">
                <p>Czas realizacji: {offer.var2_timing} dni</p>
              </div>
            </div>
            <div className="mt-6 flex flex-col items-center">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Panel administracyjny</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Łatwe integracje</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Wyższe bezpieczeństwo</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Rozszerzone SEO</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Wydajniejsza i szybsza strona</span>
                </li>
              </ul>
            </div>
          </div>{" "}
          <div className="flex flex-col relative mt-12 lg:mt-0">
            <div className="absolute z-50 -top-6 flex flex-col items-center justify-center w-full ">
              <p className="bg-white text-center px-4 py-1 mainColor rounded-xl firstShadowLight">
                REKOMENDOWANY
              </p>
            </div>{" "}
            <div className="fancy mixedGradients lightWhite backdrop-blur-xl overflow-hidden firstShadowLight flex flex-col items-center justify-start rounded-xl gap-1">
              <div className="px-2 py-6 flex flex-col w-full items-center justify-start z-10">
                {" "}
                <p className="text-white uppercase">Wariant nr III</p>
                <p className="text-white text-5xl fontRegular">
                  {offer.var3_price} zł
                </p>
                <p className="text-white text-sm">netto</p>
                <span
                  className="w-3/4 bg-white my-4"
                  style={{ height: "1px" }}
                ></span>
                <p className="text-white text-base text-center">
                  Indywidualny projekt + Gotowy CMS
                </p>
              </div>
              <div className="bg-white mainColor w-full p-2 text-center z-10">
                <p>Czas realizacji: {offer.var3_timing} dni</p>
              </div>
            </div>
            <div className="mt-6 flex flex-col items-center">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Panel administracyjny</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Łatwe integracje</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Wyższe bezpieczeństwo</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Rozszerzone SEO</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Wydajniejsza i szybsza strona</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col">
            {" "}
            <div className="lightWhite backdrop-blur-xl overflow-hidden flex flex-col items-center justify-start rounded-xl gap-1">
              <div className="px-2 py-6 flex flex-col w-full items-center justify-start">
                {" "}
                <p className="text-white uppercase mainColor">Wariant nr IV</p>
                <p className="text-white text-5xl fontRegular">
                  {offer.var4_price} zł
                </p>
                <p className="text-white text-sm">netto</p>
                <span
                  className="w-3/4 bg-white my-4"
                  style={{ height: "1px" }}
                ></span>
                <p className="text-white text-base text-center mainColor">
                  Indywidualny projekt oraz CMS
                </p>
              </div>
              <div className="bg-white w-full p-2 text-center">
                <p>Czas realizacji: {offer.var4_timing} dni</p>
              </div>
            </div>
            <div className="mt-6 flex flex-col items-center">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Panel administracyjny</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Łatwe integracje</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Wyższe bezpieczeństwo</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Rozszerzone SEO</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-100">
                  <FaCheck />
                  <span>Wydajniejsza i szybsza strona</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-6 pt-12">
        <a href="#na-co-zwrocic-uwage" className="firstButton w-48 text-center">
          Poznaj różnice
        </a>
        <a href="#skad-wynika-cena" className="secondButton w-48 text-center">
          Skąd wynika cena
        </a>
      </div>
    </div>
  );
}
export default PricingWebsite;
