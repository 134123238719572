import { useState } from "react";
function Faq() {
  const initialQuestions = [
    {
      question: "Ile będzie kosztować mój projekt?",
      answer:
        "Koszt produktów informatycznych uzależniony jest od wielu czynników. Na końcową cenę wpływa m.in. struktura strony/aplikacji, funkcjonalności oraz wybrana technologia. Aby uzyskać dokładną wycenę skorzystaj z formularza wyceny. Z naszych usług korzystają zarówno osoby fizyczne i freelancerzy, jak i segment MŚP oraz duże firmy.",
      isOpen: false,
    },
    {
      question: "Jak długo trwa realizacja projektu?",
      answer:
        "Czas realizacji uzależniony jest od wymagań projektu oraz wybranej technologii. Umów się na bezpłatną konsultację, a przedstawimy Ci dokładny harmonogram realizacji projektu.",
      isOpen: false,
    },
    {
      question: "Czy jest gwarancja?",
      answer:
        "Tak, każdy projekt obejmowany jest 48-miesięczną gwarancją. Dodatkowo zapewniamy bezterminowe, bezpłatne wsparcie dostępne za pośrednictwem infolinii, maila oraz naszego autorskiego panelu Klienta. Warto zaznaczyć, że nikt nie musiał korzystać z naszej gwarancji!",
      isOpen: false,
    },
    {
      question: "Dlaczego wybrać iFil?",
      answer:
        "Zaopiekujemy każdy aspekt Twojego projektu informatycznego. Dbamy nie tylko o interfejs graficzny, czyli to co widzi użytkownik. Przeprowadzamy kompleksową optymalizację pod kątem wydajności, prędkości, dobrych praktyk, pozycjonowania technicznego oraz dostępności. Dysponujemy działem wsparcia dostępnym przez cały tydzień za pomocą maila i czatu na żywo, ale także pod infolinią od poniedziałku do piątku. Podczas realizacji projektu będziesz mieć kontakty jedynie ze swoim opiekunem projektu, dzięki temu unikniesz 'przerzucania' pomiędzy kilku współpracowników. Nie musisz martwić się o techniczne aspekty realizacji, zadbamy o wdrożenie Twojej realizacji do sieci oraz utrzymania jej na serwerze. Co więcej dysponujemy własną aplikacją do ofertowania Let's Offer, do której otrzymasz dożywotni dostęp bezpłatnie w ramach każdej realizacji. Let's Offer pozwoli Ci przenieść swoje oferty dla Klientów na wyższy poziom.",
      isOpen: false,
    },
    {
      question: "Jak wygląda nasza oferta?",
      answer:
        "Każda nasza oferta składa się z 3-4 wariantów. Warianty różnią się wykorzystaną technologią, która wpływa m.in. na prędkość ładowania projektu, współczynnik konwersji oraz pozycjonowanie. Przy każdej ofercie przedstawiamy swoją rekomendację oraz porównanie z innymi wariantami",
      isOpen: false,
    },
    {
      question: "Jaki jest limit podstron / zakładek?",
      answer:
        "Nie ma! Nie rozliczamy się na podstawie ilości podstron / zakładek. Zdajemy sobie sprawę, że projekt informatyczny składa się z wielu aspektów. W związku z tym jesteśmy elastyczni i w razie potrzeby tworzymy dodatkowe widoki. W naszych ofertach nie spotkasz się ze wskazaną liczbą podstron.",
      isOpen: false,
    },
    {
      question: "Jak wygląda wsparcie po projekcie?",
      answer:
        "W ramach każdego projektu - bez względu na cenę - zapewniamy bezterminowe wsparcie. Oznacza to, że zawsze możesz liczyć na naszą pomoc w razie jakichkolwiek problemów związanych z projektem. Wsparcie dostępne jest za pośrednictwem infolinii, maila oraz naszego autorskiego panelu Klienta.",
      isOpen: false,
    },
    {
      question: "Czy mogę dokonywać samodzielnie zmian w projekcie?",
      answer:
        "W razie potrzeby samodzielnego wprowadzania zmian w projekcie implementujemy intuicyjny panel administracyjny. Co więcej zapewniamy bezpłatne szkolenie z obsługi panelu oraz zestaw poradników.",
      isOpen: false,
    },
    {
      question: "Jestem osobą nietechniczną, co potrzebuję wiedzieć?",
      answer:
        "Każda nasza realizacja jest kompleksowa, Opiekun Projektu odpowie na wszystkie Twoje pytania oraz przeprowadzi się przez cały proces realizacji bezproblemowo.",
      isOpen: false,
    },
    {
      question: "W jaki sposób prowadzona jest współpraca?",
      answer:
        "Proces dostosowujemy do preferencji klienta. Możemy zarówno prowadzić korespondencję całkowicie mailowo, telefonicznie bądź z wykorzystaniem takich narzędzi jak Microsoft Teams oraz Zoom. Każdy Klient otrzymuje również dostęp do portalu klienta, gdzie znajduje się harmonogram realizacji projektu oraz wszelkie informacje związane z projektem - a nawet czat!",
      isOpen: false,
    },
    {
      question: "Pomożecie mi w marketingu?",
      answer:
        "Tak. Nie jesteśmy jednak agencją marketignową. Specjalizujemy się w realizacji projektów informatycznych. Współpracujemy jednak z firmą WIDOCZNI, która zaopiekuje się marketingiem oraz SEO Twojego projektu.",
      isOpen: false,
    },
  ];

  const [questions, setQuestions] = useState(initialQuestions);

  const handleToggle = (index: number) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
      return updatedQuestions;
    });
  };

  return (
    <>
      <section
        id="faq"
        className="blackColorBg pb-12 pt-12 lg:pt-0 lg:pb-0 px-6 lg:px-48 flex flex-col items-center justify-center overflow-hidden relative"
      >
        <p className="text-white px-6 rounded-xl py-1 mixedGradients">FAQ</p>
        <h2 className="text-3xl lg:text-6xl text-center mb-6 lg:mb-12">
          <span className="whiteHeading fontRegular">Najczęściej zadawane</span>{" "}
          <span className="colorHeading fontRegular">pytania</span>
        </h2>

        {questions.map((q, index) => (
          <div
            key={index}
            className={`lightWhite faqBox px-6 w-full lg:w-2/3 rounded-xl flex flex-col mb-4 items-center z-50 justify-between ${
              q.isOpen ? "open" : ""
            }`}
          >
            <div
              className="flex flex-row items-center justify-between w-full cursor-pointer"
              onClick={() => handleToggle(index)}
            >
              <p className="text-white py-4 text-base lg:text-xl mt-1">
                {q.question}
              </p>
              <svg
                width={25}
                height={25}
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>plus-circle</title>{" "}
                    <desc>Created with Sketch Beta.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Icon-Set"
                        transform="translate(-464.000000, -1087.000000)"
                        fill="#ffffff"
                      >
                        {" "}
                        <path
                          d="M480,1117 C472.268,1117 466,1110.73 466,1103 C466,1095.27 472.268,1089 480,1089 C487.732,1089 494,1095.27 494,1103 C494,1110.73 487.732,1117 480,1117 L480,1117 Z M480,1087 C471.163,1087 464,1094.16 464,1103 C464,1111.84 471.163,1119 480,1119 C488.837,1119 496,1111.84 496,1103 C496,1094.16 488.837,1087 480,1087 L480,1087 Z M486,1102 L481,1102 L481,1097 C481,1096.45 480.553,1096 480,1096 C479.447,1096 479,1096.45 479,1097 L479,1102 L474,1102 C473.447,1102 473,1102.45 473,1103 C473,1103.55 473.447,1104 474,1104 L479,1104 L479,1109 C479,1109.55 479.447,1110 480,1110 C480.553,1110 481,1109.55 481,1109 L481,1104 L486,1104 C486.553,1104 487,1103.55 487,1103 C487,1102.45 486.553,1102 486,1102 L486,1102 Z"
                          id="plus-circle"
                        >
                          {" "}
                        </path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </svg>
            </div>
            <div className="text-white w-full flex flex-col items-start justify-start mt-2">
              {q.isOpen && <p className="opacity-60 pb-4">{q.answer}</p>}
            </div>
          </div>
        ))}

        <div className="flex flex-col lg:flex-row items-center justify-center gap-6 mt-6 z-50">
          <p className="text-white text-xl">
            Nie znalazłeś odpowiedzi na pytanie?
          </p>
          <a href="/bezplatna-konsultacja">
            <button className="firstButton flex flex-row items-center justify-center gap-1">
              <span>Porozmawiajmy</span>
              <svg
                width={25}
                height={25}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                    stroke="#ffffff"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </a>
        </div>
      </section>
    </>
  );
}

export default Faq;
