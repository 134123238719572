import Hero from "../components/portfolio/hero";
import LogoSlider from "../components/portfolio/logoSlider";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
import Cards from "../components/portfolio/cards";
import BackgroundSquare from "../components/common/squareBg";
function Portfolio() {
  return (
    <>
      <Helmet>
        <title>Portfolio | iFil Group</title>
        <meta name="title" content="Portfolio | iFil Group" />
        <meta
          name="description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
          key="desc"
        />
        <meta property="og:title" content="Portfolio | iFil Group" />
        <meta
          property="og:description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <div className="w-screen h-screen fixed top-0 left-0">
          {" "}
          <BackgroundSquare />
        </div>
        <Hero />
        <LogoSlider />
        <Cards />
      </main>
      <Footer />
    </>
  );
}
export default Portfolio;
